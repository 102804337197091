import React, { useMemo, useState } from 'react';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePersistedDataStore } from '../store';

const fields = [
  { field: 'id', headerName: '#', width: 80 },
  { field: 'parcel_id', headerName: 'Parcel ID' },
  { field: 'Address', headerName: 'Address' },
  { field: 'Town', headerName: 'Town' },
  { field: 'ZipCode', headerName: 'Zip Code' },
  { field: 'IFMV', headerName: 'IFMV' },
  { field: 'Taxes', headerName: 'Taxes' },
  { field: 'WorkupValueAvg', headerName: 'Workup Value Avg' },
  { field: 'WorkupValueMid', headerName: 'Workup Value Mid' },
  { field: 'WorkupValueAvgOptim', headerName: 'Workup Value Avg (Optimized)' },
  { field: 'WorkupValueMidOptim', headerName: 'Workup Value Mid (Optimized)' },
  { field: 'MarketingValuePrediction', headerName: 'Marketing Value Prediction' },
  { field: 'ProjectedPotentialSavings', headerName: 'Projected Potential Savings' },
  { field: 'SalePrice', headerName: 'Sale Price' },
  { field: 'SalePrice_pred', headerName: 'Sale Price Prediction' },
  { field: 'Total_Possible_Neighbors', headerName: 'Total Possible Neighbors' },
  { field: 'Total_Regular_sales', headerName: 'Total Regular Sales' },
  { field: 'Total_Optimize_Sales', headerName: 'Total Optimize Sales' },
  { field: 'PropertyAssessment', headerName: 'Property Assessment' },
  { field: 'SaleDate', headerName: 'Sale Date' },
  { field: 'Name', headerName: 'Name' },
  { field: 'FirstName', headerName: 'First Name' },
  { field: 'LastName', headerName: 'Last Name' },
  { field: 'SettledMktVal', headerName: 'Settled Market Value' },
  { field: 'URL', headerName: 'URL' },
  { field: 'NassauPID', headerName: 'Nassau PID' },
];

const CustomToolbar = () => (
  <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem', justifyContent: 'space-between' }}>
    <div>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
        <GridToolbarExport />
    </div>
    <GridToolbarQuickFilter />
  </div>
);

export default function MarketingTable() {
  const getMarketingResponse = usePersistedDataStore((state) => state.marketingResponse);
  const getTotalCases = usePersistedDataStore((state) => state.totalCases);
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const columns = useMemo(() => {
    return fields.map(({ field, headerName, width }) => {
      // Define which columns need number formatting
      const numericFields = [
        'IFMV',
        'Taxes',
        'WorkupValueAvg',
        'WorkupValueMid',
        'WorkupValueAvgOptim',
        'WorkupValueMidOptim',
        'MarketingValuePrediction',
        'ProjectedPotentialSavings',
        'SalePrice',
        'SalePrice_pred',
        'Total_Possible_Neighbors',
        'Total_Regular_sales',
        'Total_Optimize_Sales',
        'PropertyAssessment',
        'SettledMktVal',
      ];
  
      return {
        field,
        headerName,
        width: width || 150,
        valueFormatter: numericFields.includes(field)
          ? (value) => {
              const valueNum = value;
              return !isNaN(Number(valueNum)) && valueNum!=='' ? Math.round(Number(valueNum)).toLocaleString() : valueNum || '';
            }
          : undefined, // Only format numbers for numeric fields
      };
    });
  }, []);

  useMemo(() => {
    if (Object.keys(columnVisibilityModel).length === 0) {
      setColumnVisibilityModel(
        fields.reduce((acc, { field }) => {
          acc[field] = true;
          return acc;
        }, {})
      );
    }
  }, [fields, columnVisibilityModel]);

  console.log(getMarketingResponse)
  return (
    <div className="h-full flex flex-col mt-8">
      {/* Status Text and Loader */}
      {getIsFetching && (
      <Box className='justify-center' display="flex" alignItems="center" mb={1}>
        <Typography variant="h6" component="div">
          Calculated {getMarketingResponse?.length || 0} out of {getTotalCases || 'loading...'}
        </Typography>
        {getIsFetching && <CircularProgress size={20} sx={{ ml: 2 }} />}
      </Box>
      )}

      {/* Data Grid */}
      <DataGridPro
        rows={getMarketingResponse || []} // Rows are already pre-processed
        columns={columns}
        rowBuffer={5} // Adjust buffer size for smoother scrolling
        style={{ height: '80vh' }} // Set explicit height        
        slots={{ toolbar: CustomToolbar }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        checkboxSelection
        loading={!getMarketingResponse || getMarketingResponse.length === 0} // Display loading state when no rows
      />
    </div>
  );
}
