import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import ReactDOM from "react-dom";
import { usePersistedDataStore } from "../store";
import { useLocation } from 'react-router-dom';
import Add from '@mui/icons-material/Add';

function CompSearch({ handleClose, compSelectCallback, searchData, placeholderText = "PID or Address" }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const [isPidSearch, setIsPidSearch] = useState(false);
  const inputRef = useRef(null);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const comp = parseInt(params.get('comp')) - 1;
  const [addComp, setAddComp] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === "") {
      setSuggestions([]);
      setLoading(false);
      setDebouncedTerm("");
    } else {
      setLoading(true);
    }
  };

  // const handleAddComp = () => {
  //   setAddComp(false);
  //   // handleClose();
  // }

  // Debounce search term
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length > 0) {
        setDebouncedTerm(searchTerm);
      } else {
        setSuggestions([]);
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Fetch suggestions when debouncedTerm changes
  useEffect(() => {
    const fetchData = async () => {
      if (debouncedTerm) {
        const isPidSearchPattern = /^[A-Za-z]\d{4}/.test(debouncedTerm) && /-/.test(debouncedTerm);
        setIsPidSearch(isPidSearchPattern);
  
        // this is termporary
        let searchLocale = isPidSearchPattern
          ? getCompSheet[comp]?.parcel_id
          : getCompSheet[comp]?.Address;

        if(!searchData){
          searchData = searchLocale
        }
        
        if (searchData) {
          // Prepare searchData with suggestion keys and index preservation
          console.log(searchData)
          searchLocale = searchData.map((item, index) => {
            if (typeof item === "string") {
              // Wrap string as an object with index
              // TEMPORARILY USING COMPSHEET TO PUT IN PID.
              return { suggestion: item, index, parcel_id: getCompSheet[comp]?.parcel_id[index] };
            } else if (item && typeof item === "object") {
              // Ensure each object has a "suggestion" key and preserve index
              const suggestionKey = isPidSearchPattern ? "PID" : "Address";
              return {
                suggestion: item.suggestion || item[suggestionKey],
                index,
                parcel_id: getCompSheet[comp]?.parcel_id[index]
              };
            }
            return null; // Filter out invalid items
          }).filter(Boolean); // Remove null or undefined entries
        }
  
        // Perform search on the transformed searchLocale
        const found = searchLocale?.filter((item) =>
          item?.suggestion?.toLowerCase().includes(debouncedTerm.toLowerCase())
        );
  
        setSuggestions(found || []);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [debouncedTerm, getCompSheet, comp, searchData]);


  // Calculate dropdown position dynamically
  useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownStyle({
        position: "absolute",
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        zIndex: 9999,
      });
    }
  }, [inputRef.current, searchTerm]);

  return (
    <div className="relative w-full">
      <TextField
        ref={inputRef}
        placeholder={placeholderText}
        variant="standard"
        fullWidth
        value={searchTerm}
        onChange={handleInputChange}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <div style={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {loading && <CircularProgress size={20} />}
              </div>
            </InputAdornment>
          ),
        }}
      />
      {suggestions.length > 0 &&
        ReactDOM.createPortal(
          <ul
            className="bg-white border shadow-md p-1 rounded-md max-h-60 overflow-y-auto"
            style={dropdownStyle}
          >
            {suggestions.map(({ suggestion, index, parcel_id }, suggestionIndex) => (
              <li
                key={suggestionIndex}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  compSelectCallback({ suggestion, index, parcel_id });
                  setSuggestions([]);
                  setSearchTerm("");
                  setDebouncedTerm("");
                }}
              >
                {suggestion}
              </li>
            ))}
          </ul>,
          document.body // Render outside the DataGridPro container
        )}
      {debouncedTerm && suggestions.length === 0 && !isPidSearch &&
        ReactDOM.createPortal(
          <ul className="bg-white border shadow-md p-1 rounded-md" style={dropdownStyle}>
            <li className="px-4 py-2 text-center">No matching addresses</li>
          </ul>,
          document.body
        )}
        {debouncedTerm && suggestions.length === 0 && isPidSearch &&
        ReactDOM.createPortal(
          <ul className="bg-white border shadow-md p-1 rounded-md" style={dropdownStyle}>
            <div className="flex justify-center items-center hover:bg-gray-200 cursor-pointer" onClick={() => {
              // setAddComp(true);
              compSelectCallback(debouncedTerm);
            }}>
            <Add></Add>
            <li className="px-4 py-2 text-center">Add PID</li>
            </div>
          </ul>,
          document.body
        )}
    </div>
  );
}

export default CompSearch;
