
import React, { useState } from 'react';

const AdjustmentsSummaryDisplay = ({adjustments, sqftDisplay}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;
  
    return (
      <div className="flex items-center text-xs">
        {/* Display adjustments */}
        <span
          className=" mr-2"
          aria-haspopup="true"
        >
          {/* {typeof adjustments.Sqft === 'number' ? `$${adjustments.Sqft.toLocaleString()}/Sqft` : adjustments.Sqft} */}
          {`$${sqftDisplay.toLocaleString()}/Sqft`}
        </span>
        <span
          className=""
          aria-haspopup="true"
        >
          {typeof adjustments.Acres === 'number' ? `$${adjustments.Acres.toLocaleString()}/Acre` : adjustments.Acres}
        </span>

      </div>
    );
  } 

    export default AdjustmentsSummaryDisplay;