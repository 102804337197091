import React, {useState, useMemo, useRef, useEffect, useCallback} from 'react';
import { usePersistedDataStore, useCompStore } from '../store';
import MuiTable from "./MuiTable";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import { blue } from '@mui/material/colors';
import TableCustomToolbar from './TableCustomToolbar';
import { saveComps } from '../../common/saveComps'
import Map from './Map';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import cloneDeep from 'lodash/cloneDeep';
import axiosInstance from '../../axiosConfig';
import MiscAdjustmentModal from './MiscAdjustmentModal';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import debounce from "lodash/debounce";
import NegotiationTable from './NegotiationTable';
import PropertyInfoPage from './PropertyInfoPage';
import { useStreamProcessorNew } from '../../common/useStreamProcessorNew';
import {calculateHeaders} from '../../common/CalculateHeaderFlex';


const CustomCheckboxHeader = React.memo(({ indeterminate, onChange }) => { // if this could access the api ref 
  return (
    <div
    className={`items-center justify-center content-center m-auto flex h-full w-full ${indeterminate ? '' : ''}`}
    style={{
      width: 22, // Match the size of your checkbox
      height: 22, // Match the size of your checkbox
    }}
  >
      {indeterminate ?
      <Checkbox
        indeterminate={indeterminate}
        onChange={onChange}
        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} />} // Ensure consistent fontSize for unchecked state
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 26, // Apply consistent sizing to all icons
          },
        }}
      />
      :
      <div className='w-[10px]'>#</div>
    }

    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.allRowsSelected === nextProps.allRowsSelected &&
          prevProps.indeterminate === nextProps.indeterminate;
});

const CustomCheckboxCell = React.memo(({ checked, selectedRowNumber }) => {
  let number = selectedRowNumber // if selectedrownumber = 0 only way itll be selected.
  return (
    <div className='items-center justify-center flex h-full'>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} />}
        checkedIcon={
          <div style={{
            position: 'relative',
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: blue[700],
            borderRadius: '10%',
          }}>
            <span style={{
              position: 'absolute',
              fontSize: 16,
              color: '#fff',
              fontWeight: 'bold',
            }}>
              {number}
            </span>
          </div>
        }
        checked={selectedRowNumber>0 ? checked:false}
      />
    </div>
  );
});


export const AdjustmentCell = ({
  params,
  selectedAdjustments,
  caseObject,
  handleAddAdj,
  savedComps,
  subject = false,
  handleDeleteAdj,
}) => {
  let value = params.value;
  const salePrice = params.row.SalePrice || 0;
  const parcelID = params.row.parcel_id;
  let indexInSavedComps = savedComps.indexOf(parcelID);

  const adjustments =
    Object.keys(selectedAdjustments).length > 0
      ? selectedAdjustments
      : caseObject.MiscAdjustments;

  if (indexInSavedComps === -1 && !subject) {
    value = value.toLocaleString() || '';
    return (
      <div className="flex font-bold justify-end items-center">
        {value !== undefined && value !== null && value !== '' ? (
          <span className="justify-end">{value}</span>
        ) : (
          'Error'
        )}
      </div>
    );
  }

  // console.log(params.row.PropertyInfo.Address)
  const adjustmentTooltips = [];
  let totalAdjustment = 0;
  // console.log(adjustments)
  for (const key in adjustments) {
    // WHY IS THE SUBJECT ADJ FLAG RELEVANT HERE?
    // It should really just be "if the adj is applied" or not.
    const adjustmentData = adjustments[key];

    const subjectAdjustmentFlag = adjustmentData.Values[0] || 0;
    // if subj doesnt have, you should be just taking the actual directional adjustment value. if the subject DOES have, 
    const compAdjustmentFlag = adjustmentData.Values[indexInSavedComps + 1] || 0;
    const adjustmentValue = Number(adjustmentData.Adjustment) || 0;
    const adjustmentType = adjustmentData.AdjustmentType;

    let adjustment = 0;
    let displayAdjustment = adjustmentValue;

    // Correct logic for adjustment direction
    const flagDifference = compAdjustmentFlag - subjectAdjustmentFlag;

    if (adjustmentType === 'percentage') {
      adjustment = (adjustmentValue / 100) * salePrice * flagDifference;
      displayAdjustment = `${adjustmentValue}% (${Math.round(adjustment).toLocaleString()})`;
    } else {
      adjustment = adjustmentValue * flagDifference;
      displayAdjustment = `$${Math.round(adjustment).toLocaleString()}`;
    }

    // console.log('Adjustment:', adjustment);

    if (adjustment !== 0) {
      totalAdjustment += adjustment; // Accumulate adjustment
      adjustmentTooltips.push({
        key,
        name: adjustmentData.Name,
        type: adjustmentType,
        adjustmentValue: flagDifference < 0 ? `-${displayAdjustment}` : displayAdjustment,
        adjustment,
        parcelId: parcelID,
      });
    }
  }

  // Apply total adjustments to the original value
  if (!isNaN(value) && value !== '') {
    value = Number(value) + totalAdjustment;
    value = Number(value.toFixed(0)).toLocaleString();
  }

  return (
    <div className="flex group justify-end h-full relative focus-hidden"
    tabIndex={0}
    onFocus={(e) => e.stopPropagation()}
    role="gridcell"
    >
      <div className="hidden w-6 group-hover:flex relative items-center justify-center">
        <IconButton
          className="mr-0"
          onClick={() => handleAddAdj(parcelID)}
          sx={{ padding: '1px', fontSize: '0.5rem' }}
        >
          <AddIcon />
        </IconButton>
      </div>

      {adjustmentTooltips.length > 0 ? (
        <>
        <Tooltip
          title={
            <div
              style={{
                padding: '8px',
                maxWidth: '300px', // Set a max width if desired
                overflowY: 'auto', // Allow vertical scrolling if too long
                maxHeight: '400px', // Set a max height to prevent overflow
                whiteSpace: 'normal', // Allow text to wrap
              }}
            >
              {adjustmentTooltips.map((adjustmentData) => (
                <div key={adjustmentData.key} className="flex items-center justify-between">
                  <span>{`${adjustmentData.name}: ${adjustmentData.adjustmentValue}`}</span>
                  <IconButton
                    onClick={(e) => {
                      handleDeleteAdj(adjustmentData);
                    }}
                    sx={{ color: 'red' }}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </div>
          }
          placement="top"
          arrow
        >
          <Typography className="flex items-center text-orange-500 cursor-pointer">
            <span className="mr-1">$</span>
            <span className="font-bold text-black text-sm">{value}</span>
          </Typography>
        </Tooltip>
      </>
      
      ) : (
        <div className="flex font-bold justify-end items-center">
          {value !== undefined && value !== null && value !== '' ? (
            <span className="justify-end">{value}</span>
          ) : (
            'Error'
          )}
        </div>
      )}
    </div>
  );
};

export const TimeAdjustmentCell = ({ subject, getTimeAdjustmentSettings, getEvalDate }) => {
  try {

    // Default value if the timeadjs don't exist === saledate
    const key = getTimeAdjustmentSettings?.saleDateType === 'contract' ? 'ContractDate' : 'SaleDate';
    const contractDate =  subject?.[key] ? new Date(subject[key]) : null;
    const evalDate = getEvalDate ? new Date(getEvalDate) : null;
    if (!contractDate || isNaN(contractDate)) {
      return <div className="flex group justify-end h-full relative text-gray-500 italic"></div>;
    }

    if (!evalDate || isNaN(evalDate)) {
      return <div className="flex group justify-end h-full relative text-gray-500 italic">No Eval Date</div>;
    }

    if (!getTimeAdjustmentSettings?.timeAdjustmentValue) {
      return <div className="flex group justify-end h-full relative text-gray-500 italic" role="gridcell">Set Value</div>;
    }
    // Extract and parse dates
    // const contractDate = new Date(subject.ContractDate);

    // Calculate the difference in days
    const diffInTime = evalDate.getTime() - contractDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    // Calculate the percentage of the year
    const percentageOfYear = diffInDays / 365;

    // Apply the adjustment based on settings
    const adjustmentRate = parseFloat(getTimeAdjustmentSettings.timeAdjustmentValue);
    if (isNaN(adjustmentRate)) {
      throw new Error("Invalid time adjustment rate provided.");
    }

    const adjustmentValue = subject.SalePrice * (percentageOfYear * (adjustmentRate / 100));
    const adjustedPrice = subject.adj_price + adjustmentValue;

    // Render the adjusted price
    return (
      <div className="flex group justify-end h-full relative" role="gridcell">
        {Math.round(adjustedPrice).toLocaleString()}
      </div>
    );
  } catch (error) {
    // Render error state
    console.error(error.message);
    return (
      <div className="flex group justify-end h-full relative text-red-500">
        {error.message}
      </div>
    );
  }
};

// Function to extract the offer value or return "nc" for "stip no change" cases.
// Debounce the toast error function to trigger after 1 second
const useDebouncedToast = () => {
  const lastTriggeredRef = useRef(0); // Track the last time the toast was triggered

  const debouncedToastRef = useRef(
    debounce((message) => {
      const now = Date.now();
      if (now - lastTriggeredRef.current > 5000) { // Only trigger if 5 seconds have passed
        lastTriggeredRef.current = now;
        toast.error(message);
      }
    }, 1000) // Debounce typing for 2 seconds
  );

  return debouncedToastRef.current;
};

// Note to self, you need to ensure that your function references are dependencies in your callbacks.
// Just traced down a dumbass bug because of this.

// VERY IMPORTANT NOTE - The utilization of MUI's api reference may not be stable indefinitely.
// We updated to the paid plan but haven't fully transitioned the code, I think we now have the ability to do multiple row updates at once
// instead of the iterative single update we're currently doing.
// Remove the casenotes and offervalue to diff states, and do not rerender grid on negotiation obj changes.
const CaseReview = ({originalCompObject, addCompNotInResponseCallback, originalCaseObject, originalOptimizedObject, originalSavedComps, resetUpdateArrayCallback, isUpdated, compStreamObject, updatedArray, handleUpdateStateCallback, globalCompRef}) => {  // update baths field to be "half baths", full baths, and total baths which is displayed by default.
  // This component is poorly optimized. this can be observed if you try to rapidly clickthrough the comps.
  // This issue is made worse by the home rerendering on view swap - which is dumb.
  const location = useLocation();
  const getStyleOptions = usePersistedDataStore((state) => state.styleOptions);
  const getReportType = usePersistedDataStore((state) => state.reportType);
  const getViewOptions = usePersistedDataStore((state) => state.viewOptions);
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
  const queryParams = new URLSearchParams(location.search);
  const comp = useMemo(() => ( parseInt(queryParams.get('comp')) - 1 || 0), [queryParams]);
  const compObject = useMemo(() => originalCompObject[comp], [originalCompObject, comp]);
  const getTimeAdjustmentSettings = usePersistedDataStore((state) => state.timeAdjustmentSettings);
  const getEvalDate = usePersistedDataStore((state) => state.evalDate);
  // Have this be an object, and have the object get updated from your dialog onsave.
  // Should memoize these to keep stable references.
  const caseObject = useMemo(() => originalCaseObject[comp], [originalCaseObject,comp]);
  const optimizedObject = useMemo(() => originalOptimizedObject[comp], [originalOptimizedObject,comp]);
  const savedComps = useMemo(() => Object.values(caseObject.Comps), [caseObject.Comps]);
  isUpdated = updatedArray.length === 0 ? true : updatedArray[comp];
  const taxYear = useMemo(() => {
    return parseInt(queryParams.get('TaxYear')) || getNegotiationObj.TaxYear; // Correctly fetch TaxYear and handle NaN
  }, [queryParams]);
  const village = queryParams.get('village') || 'All';
  const adjustmentRef = useRef({});
  const [rowUpdate, setRowUpdate] = useState(false);
  const [compObjectToDisplay, setCompObjectToDisplay] = useState(null);
  const memoizedRowUpdate = useMemo(() => rowUpdate, [rowUpdate]);
  const getAdjustments = useCompStore((state) => state.adjustments);
  
  // create a dynamic adjustment function for if you change your adjustment object to recalculate adjusted saleprices.
  // Remember these values do NOT save.

  // update this so that depending on the Runtype it uses the IFMV or the SalePrice
  function calculateAdjustments(compObject, getAdjustments, getTimeAdjustmentSettings, getEvalDate) {
    console.log(getNegotiationObj.reportType)
    const startingField = getNegotiationObj.reportType === 'standard' ? 'SalePrice' : 'IFMV';
    if (!getAdjustments.userUpdated && !getTimeAdjustmentSettings?.timeAdjustmentValue) {
      // If no updates are required, return the original compObject
      return compObject;
    }
  
    console.log("Recalculating adjustments...");
  
    // Initialize the adjustment object
    const adjustments = {};
  
    // Determine Sqft adjustment factor if 'IFMV Linked'
    const sqftAdjustmentFactor =
      getAdjustments.Sqft === "IFMV Linked" && compObject.IFMV
        ? compObject.IFMV[0] / 10000
        : null;
  
    // Iterate over features in compObject

    Object.entries(compObject).forEach(([key, valueArray]) => {
      // added descriptive warnings for if we skip so you can see why its getting wrong adjustments slightly
      if (!Array.isArray(valueArray)) {
        // console.error('value not array for this key:', key)
        return; // Skip non-array values
      }
      if (!(key in getAdjustments)){
        // console.error('key not in adjustments', key)
      return; // Skip keys not in adjustments
      }

      // console.log('value array')
      // console.log(valueArray)
  
      const subjectValue = valueArray[0];
      const adjustmentFactor =
        key === "Sqft" && sqftAdjustmentFactor !== null
          ? sqftAdjustmentFactor
          : getAdjustments[key];
  
      if (adjustmentFactor == null) return; // Skip undefined adjustment factors
  
      // Calculate adjustments for this feature
      adjustments[key] = valueArray.map((value, index) => {
        if (index === 0) return 0; // No adjustment for the subject itself
  
        const difference = subjectValue - value;
        const adjustment = difference * adjustmentFactor;
        return Math.round(adjustment * 100) / 100; // Round to 2 decimal places
      });
    }
  );

  // this has issues if subject doesn't have values, it treats them as zeroes at the moment.
  console.log('ajdustments array')
  console.log(adjustments)
  
    // Calculate adjusted prices
    const adjustedPrices = compObject[startingField].map((salePrice, index) => {
      if (index === 0) return Math.round(salePrice); // Subject price remains unchanged
  
      const totalAdjustments = Object.values(adjustments).reduce(
        (sum, adjArray) => sum + (adjArray[index] || 0),
        0
      );
  
      return Math.round(salePrice + totalAdjustments);
    });
  
    // Recalculate time adjustments if timeAdjustmentValue exists
    let timeAdj = [];
    if(getNegotiationObj.reportType === 'standard'){
    timeAdj = compObject.SalePrice.map((salePrice, index) => {
      if (index === 0 || !getTimeAdjustmentSettings?.timeAdjustmentValue) return 0;
  
      const evalDate = new Date(getEvalDate);
      const saleDateKey = getTimeAdjustmentSettings?.saleDateType === "contract" ? "ContractDate" : "SaleDate";
      const saleDate = new Date(compObject[saleDateKey][index]);
  
      if (isNaN(evalDate) || isNaN(saleDate)) return 0;
  
      const diffInDays = (evalDate - saleDate) / (1000 * 3600 * 24);
      const percentageOfYear = diffInDays / 365;
      const adjustmentRate = parseFloat(getTimeAdjustmentSettings.timeAdjustmentValue);
  
      return Math.round(salePrice * (percentageOfYear * (adjustmentRate / 100)) + adjustedPrices[index]);
    });
    }
  
    // Combine the original compObject with adjustments, adjusted prices, and time adjustments
    console.log("Adjustments recalculated successfully, time adj", timeAdj);
    return {
      ...compObject,
      adjustments, // Store adjustments separately
      adj_price: adjustedPrices, // Store final adjusted prices
      time_adj: timeAdj, // Store time adjustment values
    };
  }
  
  // Memoized compObject recalculation
  const memoizedCompObject = useMemo(() => {
    return calculateAdjustments(
      compObject,
      getAdjustments,
      getTimeAdjustmentSettings,
      getEvalDate 
    );
  }, [compObject, getAdjustments, getTimeAdjustmentSettings, getEvalDate]);

  // console.log(memoizedCompObject)
  
  
    
  const memoizedSelectedAdjustments = useMemo(() => caseObject?.MiscAdjustments || {}, [caseObject]);

  const [isSaving, setIsSaving] = useState(false); // state to set loading on the grid (doesn't work with pinned but thats a minor bug)
  // console.log('isSaving',isSaving)

  const handleSetSaving = useCallback((value) => {
    console.log('updating saving value to:', value) 
    setIsSaving(value);
  }, []);

  // This is now redundant in your refactor
  const handleNewUpdate = (response) => {
    const updatedIndex = response; // The index we want to update
    // console.log('Index updated:',response)
    // console.log('comp stored in here:',comp)
    if (comp === updatedIndex) {
      // if you are on the individual subject during stream, update this component.
      unsavedChangesRef.current = {
        originalSubject: {},
        unsavedRows: {},
        rowsBeforeChange: {},
        unsavedSubject: {},
      };
    }
  }
  
  
  const { processStream} = useStreamProcessorNew(handleNewUpdate);

  const unsavedChangesRef = useRef({
    originalSubject: {},
    unsavedRows: {},
    rowsBeforeChange: {},
    unsavedSubject: {},
  });

  const [selectedComps, setSelectedComps] = useState(null);
  const memoizedSelectedComps = useMemo(() => selectedComps, [selectedComps]);
  // useEffect(() => {
  //   // Reset selectedComps to null whenever comp changes
  //   setSelectedComps(null);
  // }, [comp]);
  // To fix this stupid dual rendering you need to lift this state into the home component I think.
  // Leaving it for now since negligible performance gain on this rerendering optimization.
  const [caseNotesChanged, setCaseNotesChanged] = useState(false);
  // const memoizedCaseNotesChanged = useMemo(() => caseNotesChanged, [caseNotesChanged]);
  const caseNotesChangedRef = useRef(null);
  const [view, setView] = useState('regular'); // changed view to be a local state here.
  const debouncedToast = useDebouncedToast();
  // the comp object that the user selects the assessor comps they want to analyze.
  const [negotiationComps, setNegotiationComps] = useState([]);
  // need a handler for updating the negotiation comps

  // get this in the caseobject from load_muni_cases - THEN figure out instantiating it correctly
  // its gonna be caseobject.OfferValue
  const memoizedCaseNotes = useMemo(() => caseObject?.CaseNotes, [caseObject?.CaseNotes]);
  // If you switch this so the memoized one is set by param, it causes a double render + rerender chain of parent.
  const memoizedOfferAmount = useMemo(() => caseObject?.OfferValue, [caseObject?.OfferValue]);

  // this works for initial render. but if you change the comp, it doesn't reset the offer amount.
  // this is a problem with the flow of the component. You need to have a parent component that resets the offer amount.

  // Toggle adjustment display modal
  const handleAddAdj = useCallback(() => {
    toggleDisplayModal()
  }, []);

  const viewCallback = useCallback((newValue) => {
    const newView = newValue === 0 ? 'regular' : newValue === 1 ? 'optimized' : 'negotiation';
    setView(newView);
  }, []);

  // REmove the property info popup display
  const handleCompViewClose = useCallback(() => {
    setCompObjectToDisplay(null);
  }, []);

  // This is stupid, it used to be WAY worse though. This casenoteschanged state still results in a double render in this.
  // Passing down to cleanup the casenotes on comp change inside mui grid.
  const handleCaseNotesCleanup = useCallback(() => {
    // console.log('invoked cleanup ')
    if(caseNotesChanged){
      console.log('casenotes were changed, cleaning up')
      setCaseNotesChanged(false);
    }
  }, [caseNotesChanged]);

  
  // extract yourr subject and comps
  const subject = useMemo(() => {
    // console.log('subjrern')
    return( Object.fromEntries(
    Object.entries(memoizedCompObject).map(([key, value]) => [key, Array.isArray(value) ? value[0] : value])
    ))
  }, [memoizedCompObject]);


  // Memoize the optimized components
  const optimizedComps = useMemo(() => {
    const workingOptimizedObject = cloneDeep(optimizedObject);
    const currentOptimizedObject = Object.fromEntries(
      Object.entries(workingOptimizedObject).map(([key, value]) => [key, Array.isArray(value) ? value.slice(1) : value])
    );
    return currentOptimizedObject
  }, [optimizedObject, savedComps]);

  // Memoize the regular components
  const regularComps = useMemo(() => {
    return Object.fromEntries(
      Object.entries(memoizedCompObject).map(([key, value]) => [key, Array.isArray(value) ? value.slice(1) : value])
    );
  }, [memoizedCompObject]);

  // the comps that selected by the user, memoized in case the higher level component re-renders at some point, haven't profiled.
  // const memoizedNegotiationComps = useMemo(() => {
  //   return negotiationComps;
  // }, [negotiationComps]);

  // const [negotiationCompsValues, setNegotiationCompsValues] = useState([])

  // memoize the negotiation comp values and have them update when negotiationComps 

  const negotiationCompsValues = useMemo(() => {
    return Object.fromEntries(
      Object.entries(memoizedCompObject).map(([key, value]) => [
        key,
        Array.isArray(value)
          ? negotiationComps.map((pid) => {
              const index = memoizedCompObject.parcel_id.indexOf(pid);
              return value[index];
            }).filter((v) => v !== undefined)
          : value,
      ])
    );
  }, [negotiationComps, memoizedCompObject]);

  // Add a new property ID (PID) to negotiationComps
  const updateNegotiationComps = useCallback(
    (pid) => {
      if (!negotiationComps.includes(pid)) {
        setNegotiationComps((prev) => [...prev, pid]);
      }
    },
    [negotiationComps]
  );

  // Remove a property from negotiationComps
  const handleDeleteProperty = useCallback(
    (property) => {
      const newNegotiationComps = negotiationComps.filter((pid) => pid !== property.parcel_id);
      setNegotiationComps(newNegotiationComps);
    },
    [negotiationComps]
  );
    


// Now you can use optimizedComps and regularComps independently
  const comps = useMemo(() => {
    if (view === 'optimized') {
      return optimizedComps;
    }else if(view === 'negotiation'){
      return negotiationCompsValues;
    }
    return regularComps;
  }, [view, optimizedComps, regularComps, negotiationCompsValues]);
  
  const tableProps = useMemo(() => ({ // custom table props for case review vverrsion of table.
    density: 'compact', // how closely spaced
    disableDensitySelector: true, // remove this option from the table header
    disableColumnResize: true, // can change this enable resizing.
    checkboxSelection: false,
    compTable: true, // in lower levvel MuiTable use this to set the backgrorund color.
    disableRowSelectionOnClick: true, // this will disable row selection on click
    disableAutosize: true,
    hideFooter: true, // remove the pagination / footer.
  }), []);
  // comps to fill in from callback. Default value should be to pass in ur regular saved.

  const intervalRef = useRef(null);
  
  // this interval ref isnt working as stable anymore
  useEffect(() => {
    // Define the interval logic
    const startInterval = () => {
      console.log('The useEffect is triggering');
  
      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      // console.
  
      // Start a new interval if conditions require it
      if ((!isUpdated || !updatedArray[comp]) && updatedArray.length>0) {
        console.log('setting interval, and invoking callback')
        intervalRef.current = setInterval(() => {
          handleUpdateStateCallback();
        }, 1000);
      }
    };
  
    // Start the interval
    if ((!isUpdated || !updatedArray[comp]) && updatedArray.length>0) {
      // console.log('setting interval, and invoking callback')
      startInterval();
    }

    // Cleanup function to clear the interval
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [updatedArray, comp, isUpdated, handleUpdateStateCallback]);

  // added this useeffect for simplicity to clear out the negotiation grids state.
  useEffect(() => {
    // console.log(negotiationCompsValues);
    // Check if the "Address" key exists and has an empty array as its value
    const isAddressEmpty = Object.keys(negotiationCompsValues).some(
      (key) => key === "Address" && Array.isArray(negotiationCompsValues[key]) && negotiationCompsValues[key].length === 0
    );
  
    if (!isAddressEmpty) {
      // Reset values only if the "Address" key is not an empty array
      // console.log(negotiationCompsValues);
      setNegotiationComps([]);
    }
  }, [comp]);

  // idk if any point to memoize, this should work. confirm doesn't cause issues.
  const handleUpdateSelectedComps = useCallback((comps) => {
    console.log('updating selected comps', comps)
    setSelectedComps(comps);
  }, [selectedComps]);

  const handleSetPropertyInfo = useCallback((comp,subject=false) => {
    comp.subject = subject;
    setCompObjectToDisplay(comp);
    // set the value here, and include a 'subject' = true flag
  }, []);

  const imputedValueCheck = useCallback((stringValue) => {
    const value = stringValue || ''; // Ensure value is at least an empty string
    if (typeof value === 'string' && value.includes('*')) {
        return (
        <div style={{ color: '#d32f2f' }} className='h-full w-full flex items-center justify-center'>
          {/* ; // Render value in red if it contains an asterisk */}
        <span className='text-red-500 h-full w-full hover:text-blue-500'>{value}</span>
        </div>
        )
    }
  }, []);


    // Funtion to actually update the inventory.
    const handleProcessDBUpdate = useCallback(async (responseObject=getNegotiationObj, weights=false) => {
      console.log('negotaiton obj')
    
      if (typeof responseObject === 'string') {
        console.log('no resp obj, means this is a NAL sale flagged run');
        console.log(getNegotiationObj);
    
        const uidArray = [...getNegotiationObj.uids]; // Clone the array to avoid mutations
    
        responseObject = {
          ...getNegotiationObj, // Start with the current negotiation object
          NALSaleRunFlag: 1, // Add the NAL sale flag
          uids: uidArray, // Add/override the uids field
          updatedSubject: uidArray[comp], // Add the updated subject field
          Records: [{ pid: responseObject }], // Add the Records field
        };
      }

      responseObject.inventoryUpdateRun = 1;
      responseObject.manualReview = 1;
    
      // Clone the `uids` array and assign it to `originalPidOrder`
      responseObject.originalPidOrder = [...responseObject.uids];
    
      // Reset `compStreamObject.current.updated` array
      compStreamObject.current.updated = new Array(responseObject.uids.length).fill(false);
    
      // Await resetting the update array callback
      await resetUpdateArrayCallback(responseObject.uids.length);
    
      // Find the index of `updatedSubject` in the `uids` array
      const updatedSubjectIndex = responseObject.uids.indexOf(responseObject.updatedSubject);
      // console.log('index of updated sub:', updatedSubjectIndex);
      // console.log('updated subj pid ', responseObject.updatedSubject);
    
      if (updatedSubjectIndex > -1) {
        // Clone the array to avoid mutating `uids`
        const updatedUids = [...responseObject.uids];

        // Reorder the array: Start from `updatedSubjectIndex`, wrap around the array
        const reorderedUids = [
          ...updatedUids.slice(updatedSubjectIndex), // From the updated subject to the end
          ...updatedUids.slice(0, updatedSubjectIndex) // From the start to the updated subject
        ];

        // Replace the original `uids` with the new, reordered array
        responseObject.uids = reorderedUids;
      }
    
      console.log('Updated subject, bumped to front of array to return first.');
      console.log(responseObject);
    
    try {
      // Need an onupdate function which will be called to process stream
      const updateInventoryResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update_inventory`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(responseObject),
    });
  
    // There is some issue wehre tghis has been happening - why? sql broken pipe?
      if (!updateInventoryResponse.ok) {
        console.log('error')
        console.log(updateInventoryResponse)
        toast.error('Error processing update - see logs for details');
        throw new Error(`Inventory update failed: ${updateInventoryResponse.statusText}`);
      }

      console.log("Inventory updated successfully. Starting stream processing...");
      // Now process your stream response as it comes back.
      const streamProcessingResponse = await processStream({
        compStreamObjectRef: compStreamObject.current,
        stream: updateInventoryResponse.body, // pass this in to then get reader.
        negotiationObj: responseObject,
        // currentComps:
      });

      toast.success('updated successfully, all cases finished re-running');
      // if(isSaving){ setIsSaving(false) }
      // handleSetStreaming(false);
  
      console.log('obj after the process')
      console.log(compStreamObject.current)
  
      // Here you don't clear the inv update out until the whole thing has re-run.
      // THis should really happen as it starts running
      // clear out the update inventory ref.
      unsavedChangesRef.current = {
        originalSubject: {},
        unsavedRows: {},
        rowsBeforeChange: {},
        unsavedSubject: {},
      };
  
  
    } catch (error) {
      console.error('Error while updating inventory:', error);
      // here, need to rever the state of things
      resetUpdateArrayCallback()
      delete compStreamObject.current.updated;
    }
  
  
      // here, invoke the function to process the fetch response you
  
      // Lastly, log the response (in the global object you get back from that processsing.)
    }, [getNegotiationObj, comp, resetUpdateArrayCallback, updatedArray, rowUpdate, processStream, handleSetSaving]);


    const handleAddressClick = useCallback((comp) => {
      setCompObjectToDisplay(comp); // Set the selected row to the state
    }, []);

    // this is where a huge cost of your rendering is. Optimizing your render cell fucntions will save render time.
    // the caseobject and savedcomps cause this to recalc header and re-render grid between every comp change.
  const headers = useMemo(() => [
    // checkbox selection (action col type)
    {
      field: 'actions',
      type: 'actions',
      hide: true,
      width: 65,
      headerClassName: 'actions',
      disableColumnMenu: true,
      renderHeader: (params) => {
        const indeterminate = params.colDef.indeterminate // this is set by selected comps, not saved
        // const indeterminate = pinnedRowsIds.top.length > 0 && pinnedRowsIds.top.length < rows.length;
        // Conditionally render the checkbox in the header
        const handleChange = (event) => {
          handleSelectAllChange(params);
        };
        return (
          <CustomCheckboxHeader
            indeterminate={indeterminate}
            onChange={handleChange}
          />
        ); // Hide header checkbox if nothing is selected
      },
      // Updating this to be based on the internal grid state the way you had it before.
      renderCell: (params) => {
        // console.log(params.row.selectedRowNumber)
        const reOrderedArray = params.colDef.reOrderArray || [];
        const selectedRows = params.colDef.selectionArray || []; // this is CORRECT now. (we drive this from MUI table.)
        let isSelected = false;

        // console.log(isSelected)
        // console.log('reordered array')
        // console.log(reOrderedArray)

        // console.log('selected rows')
        // console.log(selectedRows)

        let selectedRowNumber = '';
        if(reOrderedArray.length>0 || reOrderedArray===null){
          isSelected = reOrderedArray.includes(params.row.parcel_id);
          selectedRowNumber = reOrderedArray.indexOf(params.row.parcel_id) + 1;
        }
        // its ogttat be from this
        else if(selectedRows.length > 0){ // if > 0 you have as a user picked things.
          isSelected = selectedRows.includes(params.row.parcel_id);
          selectedRowNumber = selectedRows.indexOf(params.row.parcel_id) + 1;
        }

        // else{
        //     selectedRowNumber = savedComps.indexOf(params.row.parcel_id) + 1
        // }
        
            return <div className='flex items-center'>
            <CustomCheckboxCell 
            checked={isSelected}
            selectedRowNumber={params.row.selectedRowNumber||selectedRowNumber}
            />
            <span className='items-center min-w-[16px] cursor-pointer'> {params.row['#']}</span>
            </div>
      }
    },

    { // Prop info - address, sd, parcel id
      align: "left",
      editable: false,
      disableColumnMenu: true,
      field: "PropertyInfo",
      headerName: "Address",
      minWidth: 120,
      flex: 1, // Allows the column to grow and shrink as needed
      valueGetter: (params) => params.Address, // updated this so you can search and filter by address (use value getter for this function)
      renderCell: (params) => {
        // const ImgLink = await GetCompPhoto(params.row.parcel_id); // Fetch the image link

        // Have the link onclick set the state of a compObject to all the values below
        // And render that on top of the map      
        return (
            <span
              onClick={() => handleAddressClick(params.row)}
              style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
          textDecoration: 'underline',
              }}
              className="flex-grow"
            >
              {params.row.PropertyInfo.Address}
            </span>
        );
      }
    },

    { // Town
      editable: true,
      disableColumnMenu: true,
      field: "Town",
      headerName: "Town",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.6, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // SDName
      editable: true,
      disableColumnMenu: true,
      field: "SDName",
      headerName: "SD Name",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      flex: 0.6, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // Zip
      editable: true,
      disableColumnMenu: true,
      field: "ZipCode",
      headerName: "Zip",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.5, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // Neighborhood code
      editable: true,
      disableColumnMenu: true,
      field: "NeighborhoodCode",
      headerName: "Neighborhood",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.5, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // PID
      editable: false,
      disableColumnMenu: true,
      field: "parcel_id",
      headerName: "PID",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.9, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // PCC
    editable: false,
    disableColumnMenu: true,
    field: "PCC",
    headerName: "PCC",
    type: "string",
    flex: 0.2, // Adjust this value based on proportion from the old width
    },

    { // Style
    editable: true,
    disableColumnMenu: true,
    field: "Style",
    headerName: "Style",
    type: 'singleSelect',
    align: "left",
    // list comprehensive value options here
    valueOptions: getStyleOptions,
    hide: false,
    flex: 0.8, // Adjusted based on the old width of 100
    // cellClassName: (params) => console.log(params)
    },

    { // View
      editable: true,
      disableColumnMenu: true,
      field: "View",
      headerName: "View",
      type: 'singleSelect',
      align: "left",
      // list comprehensive value options here
      valueOptions: getViewOptions,
      hide: false,
      flex: 0.7, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // Yr
    editable: true,
    disableColumnMenu: true,
    field: "YearBuilt",
    headerName: "Yr",
    type: "number",
    flex: 0.5, // Adjust this value based on proportion from the old width
    valueGetter: (value) => {
      try {
        if (typeof value === 'string' && value.includes('*')) return value;
    
        const parsedValue = Number(value);
        if (!isNaN(parsedValue)) {
          const [integerPart, decimalPart] = parsedValue.toString().split('.');
          if (decimalPart && decimalPart.length > 4) {
            return `${parsedValue.toFixed(0)}*`;
          }
          return integerPart;
        }
      } catch (error) {
        console.error('Error processing value:', value, error);
      }
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Sqft
    editable: true,
    disableColumnMenu: true,
    field: "Sqft",
    headerName: "Sqft",
    type: "number",
    hide: false,
    flex: 0.6, // Adjusted based on the old width of 70
    valueGetter: (value) => {
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value === '') return '';
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
      const formattedValue = Math.round(parsedValue).toLocaleString()
      const [integerPart, decimalPart] = parsedValue.toString().split('.');
      if (decimalPart && decimalPart.length >= 2) {
        return `${formattedValue}*`;
      }
      return parsedValue;
      }
      return '';
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // EST sqft
      editable: false,
      disableColumnMenu: true,
      field: "EstSqft",
      headerName: "Est. Sqft",
      type: "number",
      hide: false,
      flex: 0.6, // Adjusted based on the old width of 70
      valueGetter: (value) => {
        if (typeof value === 'string' && value.includes('*')) return value;
        if(value === '') return '';
        const parsedValue = Number(value);
        if (!isNaN(parsedValue)) {
        const formattedValue = Math.round(parsedValue).toLocaleString()
        const [integerPart, decimalPart] = parsedValue.toString().split('.');
        if (decimalPart && decimalPart.length >= 2) {
          return `${formattedValue}*`;
        }
        return parsedValue;
        }
        return '';
      },
      // This render step should probably be a formatter step instead of a full custom render.
      renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Acres
    editable: true,
    disableColumnMenu: true,
    field: "Acres",
    headerName: "Acres",
    type: "number",
    minWidth: 60,
    flex: 0.6, // Adjusted based on the old width of 55
    valueGetter: (value) => {
      
      // if the value has an asterisk, just return the value.
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value === '') return '';
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
      const [integerPart, decimalPart] = parsedValue.toString().split('.');
      if (decimalPart && decimalPart.length > 4) {
        return `${parsedValue.toFixed(2)}*`;
      }
      return parsedValue.toFixed(2);
      }
      return '';
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Grade
      editable: true,
      disableColumnMenu: true,
      field: "Grade",
      headerName: "Grade",
      type: "singleSelect",
      valueOptions: ['Poor', 'Fair', 'Average', 'Good', 'Excellent'],
      minWidth: 60,
      flex: 0.6, // Adjusted based on the old width of 55
    },

    { // Condition
      editable: true,
      disableColumnMenu: true,
      field: "Condition",
      headerName: "Condition",
      type: "singleSelect",
      valueOptions: ['Poor', 'Fair', 'Average', 'Good', 'Excellent'],
      minWidth: 60,
      flex: 0.6, // Adjusted based on the old width of 55
    },

    { // Pool
      editable: true,
      disableColumnMenu: true,
      field: "Pool",
      headerName: "IG Pool",
      type: "singleSelect",
      valueOptions: ['None', 'True'],
      minWidth: 60,
      align: 'center',
      headerAlign: 'center',
      flex: 0.6, // Adjusted based on the old width of 55
    },

    { // Finished Basement
      editable: true,
      disableColumnMenu: true,
      field: "FinishedBasement",
      headerName: "Basement",
      type: "number",
      minWidth: 60,
      flex: 0.6, // Adjusted based on the old width of 55
    },

    { // Full Baths
      editable: true,
      disableColumnMenu: true,
      field: "fullBaths",
      headerName: "Full Baths",
      type: "number",
      flex: 0.6, // Adjusted based on the old width of 6
    },

    { // Half Baths
    editable: true,
    disableColumnMenu: true,
    field: "halfBaths",
    headerName: "Half Baths",
    type: "number",
    flex: 0.6, // Adjusted based on the old width of 6
    },

    { // Bathrooms
    editable: false,
    disableColumnMenu: true,
    field: "totalBaths",
    headerName: "Baths",
    type: "number",
    flex: 0.4, // Adjusted based on the old width of 40
    valueGetter: (value) => {
      // made it so BR return just blank asterisk if zero
      // if the value has an asterisk, just return the value.
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value == 0) return '0*';
      const parsedValue = Number(value);
      return parsedValue.toFixed(0);
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Assessment
    editable: true,
    disableColumnMenu: true,
    field: "PropertyAssessment",
    headerName: "Assessment",
    type: "number",
    flex: 1, // Adjusted based on the old width of 100
    valueGetter: (value) => Number(value).toLocaleString() || '',
    },

    { // IFMV
    editable: false,
    disableColumnMenu: true,
    field: "IFMV",
    headerName: "IFMV",
    type: "number",
    flex: 0.7, // Adjusted based on the old minWidth of 90
    renderCell: (params) => {
      const value = params.value;
    
      // Check for an empty string or nullish value
      if (value === "" || value == null) {
        return ""; // Return an empty string directly
      }
    
      // Proceed if the value is not an empty string and is a valid number
      if (!isNaN(value)) {
        return Number(Number(value).toFixed(0)).toLocaleString() || "";
      }
    
      return ""; // Fallback return value if none of the conditions match
    }
    },

    { // Sale Price
    editable: true,
    disableColumnMenu: true,
    field: "SalePrice",
    headerName: "Sale Price",
    type: "number",
    flex: 0.8, // Adjusted based on the old minWidth of 90
    // valueGetter: (value) => Number(value).toLocaleString() || '',
    renderCell: (params) => params.value ? Number(params.value).toLocaleString() : '',
    },

    { // Sale Date
    editable: true,
    disableColumnMenu: true,
    field: "SaleDate",
    headerName: "Sale Date",
    type: "date",
    flex: 0.8, // Adjusted based on the old minWidth of 90
    valueGetter: (value) => new Date(value) || '',
    },

    // Contract Date
    { 
      editable: true,
      disableColumnMenu: true,
      field: "ContractDate",
      headerName: "Contract Date",
      type: "date",
      flex: 0.8, // Adjusted based on the old minWidth of 90
      valueGetter: (value) => {
        try {
          const dateValue = value;
          if (!dateValue) {
            return ''; // Return empty string if ContractDate is missing
          }
          const parsedDate = new Date(dateValue);
          if (isNaN(parsedDate)) {
            throw new Error("Invalid date format.");
          }
          return parsedDate;
        } catch (error) {
          console.error("Error parsing ContractDate:", error.message);
          return ''; // Return empty string to avoid breaking the DataGrid
        }
      },
    },

    { // MLS number
      editable: true,
      disableColumnMenu: true,
      field: "MLSNumber",
      headerName: "MLS Number",
      type: "number",
      flex: 0.7, // Adjusted based on the old minWidth of 90
      // value gettter that displays blank string if the value is 'None'
      valueGetter: (value) => {
        if(value==='None'){
          return '';
        }
        return value || '';
      },
    },

    { // Adj Sale
    editable: false,
    disableColumnMenu: true,
    field: "adj_price",
    headerName: getReportType === 'assessment' ? 'Adj IFMV' : "Adj Sale",
    type: "number",
    flex: 0.8, // Adjusted based on the old width of 90
      valueGetter: (value) => {
      // console.log(value)
      if (value !== '' && value !== 0) { 
      return Number(value.toFixed(0));
      }
      // here you define the value for subject if hasn't sold.
      return '';
    },
    disableColumnMenu: true,
    renderCell: (params) => (
      <AdjustmentCell 
        params={params} 
        savedComps={savedComps}
        selectedAdjustments={memoizedSelectedAdjustments}
        caseObject={caseObject}
        handleAddAdj={handleAddAdj}
        handleDeleteAdj={handleDeleteAdj}
      />
    )
    },

    { // time adjusted sale price -- UPDATE so you calc time adj & update it instead of rendering it in rendercell component.
      field: "time_adj",
      headerName: "Time Adj",
      flex: 0.7,
      align: 'right',
      disableColumnMenu: false,
      valueGetter: (value) => {
        if (value !== '') { 
        return Number(value.toFixed(0));
        }
        // here you define the value for subject if hasn't sold.
        return 'Set Value';
      },
      renderCell: (params) => {
        const value = params.value;
        if (!value || isNaN(value)) {
          return (
            <div className="flex group justify-end h-full relative text-gray-500 italic" role="gridcell">Set Value</div>

          );
        }
        return <div>{value.toLocaleString()}</div>;
      },
    },

    { // Distance
    editable: false,
    disableColumnMenu: true,
    field: "Distance",
    headerName: "Dist (Mi)",
    type: "number",
    flex: 0.6, // Adjusted based on the old width of 100
    valueGetter: (value) => {
      // Explicitly check for 0 and ensure value is numeric
      if (value === 0) {
        return 0; // Explicitly return 0 if the value is 0
      }
      // Check for other numeric values
      if (!isNaN(value) && value !== '' && value !== null && value !== undefined) {
        return Number(Number(value).toFixed(2)); // Handle valid numeric inputs
      }
      // Do nothing for invalid inputs (undefined, null, non-numeric, empty strings)
      return null; // or undefined, depending on your desired behavior
    },
    // valueFormatter: (value) => {
    //   return value === 0 ? '0' : value || ''; // Ensure '0' renders as a string
    // },
    },
  ], [getStyleOptions, imputedValueCheck, memoizedSelectedAdjustments, rowUpdate, savedComps, caseObject, getTimeAdjustmentSettings, getEvalDate, ]);


  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedModel = localStorage.getItem('columnVisibilityModel');
    return savedModel ? JSON.parse(savedModel) : {
      PropertyAssessment: false,
      Town: false,
      ZipCode: false,
      fullBaths: false,
      halfBaths: false,
      SDName: false,
      View: false,
      parcel_id: false,
      MLSNumber: false,
      Condition: false,
      Grade: false,
      Pool: false,
      FinishedBasement: false,
      NeighborhoodCode: false,
    };
  });

  // // just directly call on component load, was having issues w/ passing down correct flex to subject row.
  // filtered here is really just 'calculated'

  const handleColumnVisibilityChange = useCallback((newModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  // use this to trigger renders within the table - if there exists a selected adj for a parcelID, then display a green $ sign
  // next to sale px. See if this top level rerender is performant enough for now.
  const [displayModal, setDisplayModal] = useState(false);
  const toggleDisplayModal = useCallback(() => {
    setDisplayModal((prev) => !prev);
  }, []);


  // Delete your misc adjustments.
  const handleDeleteAdj = useCallback(async (adjData) => {
    console.log('deleting adjustment');
    // console.log(adjData);
    // console.log(caseObject)
    try {
        // Pass this object into the backend for delete adjustment route
        const deleteAdjResponse = await axiosInstance.post('/delete_misc_adj', { //
            PID: caseObject.parcel_id,
            adjustmentData: adjData,
            MuniCode: getNegotiationObj.MuniCode,
            TaxYear: taxYear,
            villageFlag: village === 'All' ? 0 : 1, // Check village value instead of hardcoding zero
        });


        // The delete adjustment thing comes back with the value field still.
        // DOUBLE CHECK THIS THAT IT HAS THE CORRECT VALUES IN THE RESPONSE (removing the one you ust killed)
        console.log(deleteAdjResponse.data);

        // Check for successful response
        if (deleteAdjResponse.data && deleteAdjResponse.data[0] !== false) {
            toast.success(`Successfully deleted misc adjustment ${adjData.name}`);
            console.log('remaining adjustments:', deleteAdjResponse.data[1]);
            const remainingMiscAdjustments = deleteAdjResponse.data[1];
            // setSelectedAdjustments(remainingMiscAdjustments);
            // Clone the current state of cases to avoid mutating the original state
            const updatedCases = [...getNegotiationObj.cases];
            
            // Clone the specific case to update
            const currentCase = { ...updatedCases[comp] };
            
            // Update the MiscAdjustments field within the current case
            const updatedCase = {
              ...currentCase,
              MiscAdjustments: { ...remainingMiscAdjustments }, // Replace MiscAdjustments with the new adjustments
            };

            // Reassign the updated case back into the cases array
            updatedCases[comp] = updatedCase;

            // Final batched update to NegotiationObj
            const updatedNegotiationObj = {
              ...getNegotiationObj,
              cases: updatedCases,
            };

            // Update the state with the new NegotiationObj
            setNegotiationObj(updatedNegotiationObj);
            setRowUpdate(!rowUpdate)
        } else {
            // Handle the case where the response is false or not successful
            toast.error('Failed to delete miscellaneous adjustment. Please try again.');
        }
    } catch (error) {
        console.error('Error deleting adjustment:', error);
        toast.error('An error occurred while deleting the miscellaneous adjustment. Please try again.');
    }
  }, [taxYear, village, getNegotiationObj, caseObject, comp, rowUpdate]);

  // Add misc adjustment.
  const handleAddAdjustment = useCallback(async (adjustmentData) => {
    console.log(adjustmentData);
    const adjustmentDataObject = {
        Name: adjustmentData.adjustmentName,
        Adjustment: adjustmentData.adjustmentValue,
        AdjustmentType: adjustmentData.adjustmentType === '%' ? 'percentage' : 'numerical', // Change this to be "numerical or percentage based on the value"
        Values: adjustmentData.selectedProperties,
    };

    console.log('Adding adjustment');

    const userObject = JSON.parse(localStorage.getItem('userInfo'));
    console.log(caseObject);

    try {
        const miscAdjustmentResponse = await axiosInstance.post('/add_misc_adj', {
            PID: caseObject.parcel_id,
            adjustmentData: adjustmentDataObject,
            MuniCode: getNegotiationObj.MuniCode,
            TaxYear: taxYear,
            villageFlag: village === 'All' ? 0 : 1, // Check village value instead of hardcoding zero
            userID: userObject?.userId || null, // Update this from JSON local storage
            repID: caseObject.repId || null, // Update this from the negotiationObj
        });

        console.log(miscAdjustmentResponse.data);

        // Check for successful response
        if (miscAdjustmentResponse.data && miscAdjustmentResponse.data !== false) {
            toast.success('Successfully added misc adjustment');
            // setSelectedAdjustments(miscAdjustmentResponse.data);
            // Here need to update your negotiation object too:
            const updatedCases = [...getNegotiationObj.cases];
            
            // Clone the specific case to update
            const currentCase = { ...updatedCases[comp] };
            
            // Update the MiscAdjustments field within the current case
            const updatedCase = {
              ...currentCase,
              MiscAdjustments: { ...miscAdjustmentResponse.data }, // Replace MiscAdjustments with the new adjustments
            };

            // Reassign the updated case back into the cases array
            updatedCases[comp] = updatedCase;

            // Final batched update to NegotiationObj
            const updatedNegotiationObj = {
              ...getNegotiationObj,
              cases: updatedCases,
            };

            // Update the state with the new NegotiationObj
            setNegotiationObj(updatedNegotiationObj);
            setRowUpdate(!rowUpdate);
        } else {
            // Handle the case where the response is false or not successful
            toast.error('Failed to add miscellaneous adjustment. Please try again.');
        }
    } catch (error) {
        console.error('Error adding adjustment:', error);
        toast.error('An error occurred while adding the miscellaneous adjustment. Please try again.');
    }
  }, [caseObject, taxYear, village, rowUpdate, getNegotiationObj, comp]);
  

  const debouncedHandleChange = useMemo(() => {
    return debounce((value) => {
      console.log('runnin debounced')
      // Clone the current negotiation object and case for batched updates
      const updatedCases = [...getNegotiationObj.cases];
      const currentCase = { ...updatedCases[comp] };
  
      // Update the CaseNotes
      if (!caseNotesChanged) {
        console.log('updating thes tate in this')
        setCaseNotesChanged(true);
        // caseNotesChangedRef.current = true;
      }
      currentCase.CaseNotes = value;
  
      // Extract offer value directly
      const match = value.match(
        /(?:offering|offer)\s+(\d+(?:\.\d+)?[km]|\d+(?:,\d{3})*)|(\d+(?:\.\d+)?[km]|\d+(?:,\d{3})*)\s*(?:offering|offer)/i
      );
  
      let extractedOfferValue = null;
  
      if (
        /\b(?:stip\s*)?(?:n[\/\.\s]?c(?:hange)?|no\s*(?:change|chg|chg)|nochg)\b|\b(?:offer|offering)\s+(?:n[\/\.\s]?c(?:hange)?|no\s*(?:change|chg|chg)|nochg)\b/i.test(value)
      ) {
        extractedOfferValue = "nc";
      } else if (match) {
        let offerValue = match[1] || match[2];
  
        if (offerValue) {
          if (offerValue.includes("k")) {
            extractedOfferValue = parseFloat(offerValue.replace("k", "")) * 1000;
          } else if (offerValue.includes("m")) {
            extractedOfferValue = parseFloat(offerValue.replace("m", "")) * 1000000;
          } else {
            extractedOfferValue = parseInt(offerValue.replace(/,/g, ""), 10);
          }
        }
      }
  
      // Handle the extracted offer value
      const currentOfferValue = currentCase?.OfferValue;
  
      if (extractedOfferValue === "nc") {
        console.log("No change detected in offer value.");
        const offerValue = Math.round(subject.IFMV);
        if (currentOfferValue !== offerValue) {
          currentCase.OfferValue = offerValue;
        }
        debouncedToast.cancel();
      } else if (extractedOfferValue !== null) {
        if (currentOfferValue !== extractedOfferValue) {
          currentCase.OfferValue = extractedOfferValue;
        }
  
        // Trigger toast if the extracted value is significantly lower
        if (
          extractedOfferValue < subject.IFMV * 0.75 &&
          extractedOfferValue.toString().length > 5 &&
          subject.IFMV > 450000
        ) {
          debouncedToast(
            `Offer value is more than 25% less than the IFMV. Please review the offer value (max $${Math.round(
              subject.IFMV * 0.75
            ).toLocaleString()}). If this is intended, manually type in the offer value.`
          );
        } else {
          debouncedToast.cancel();
        }
      } else {
        // Clear offer amount if applicable
        if (currentOfferValue !== null) {
          currentCase.OfferValue = null;
        }
        debouncedToast.cancel();
      }
  
      // Reassign the updated case back into the cases array
      updatedCases[comp] = currentCase;
  
      // Final batched update to NegotiationObj
      const updatedNegotiationObj = {
        ...getNegotiationObj,
        cases: updatedCases,
      };
      setNegotiationObj(updatedNegotiationObj);
    }, 500); // Debounce delay of 500ms for syncing changes
  }, [caseNotesChanged, getNegotiationObj, setNegotiationObj, comp, subject.IFMV]);
  
  // Handle change callback
  const handleChangeNotes = useCallback(
    (value) => {
      debouncedHandleChange(value);
      debouncedHandleChange.flush(); // Ensure the timer resets properly on new input
    },
    [debouncedHandleChange]
  );
  
    // this function is only iterating over the regular comps. Is this a problem?
    const memoizedLatLongs = useMemo(() => { 
      let latLongs;
      const compsToDisplay = memoizedSelectedComps ? memoizedSelectedComps : savedComps;
    
      // If there are no saved comps, return the first 5 entries from `compObject`
      if (compsToDisplay.length === 0) {
        console.log('no comps saved, displaying top 5');
        latLongs = compObject.parcel_id.slice(0, 1).map((parcelId, index) => ({
          parcel_id: parcelId,
          latitude: compObject.Latitude[index] || null,
          longitude: compObject.Longitude[index] || null,
        }));
      } else {
        // If there are saved comps or selected comps, use those
        // Map over compsToDisplay to get the lat/long values
        latLongs = compsToDisplay.map(parcelId => {
          const index = compObject.parcel_id.indexOf(parcelId);
          if (index !== -1) {
            return {
              parcel_id: parcelId,
              latitude: compObject.Latitude[index],
              longitude: compObject.Longitude[index],
            };
          } else {
            return null; // Explicitly return null if no match is found
          }
        }).filter(item => item !== null); // Remove undefined or null entries
      }

      if(view==='negotiation'){ // if in negotiation view, extract from your negotiation state.
        // here, extract negotiationCompsValues.Latitude and Longitude, and parcel_id
        latLongs = negotiationCompsValues.parcel_id.map((parcelId, index) => ({
          parcel_id: parcelId,
          latitude: negotiationCompsValues.Latitude[index] || null,
          longitude: negotiationCompsValues.Longitude[index] || null,
        }));
      }
    
      // Always append the first lat/long entry from compObject
      const firstParcelId = compObject.parcel_id[0];
      const firstIndex = compObject.parcel_id.indexOf(firstParcelId);
    
      const firstLatLong = {
        parcel_id: firstParcelId,
        latitude: firstIndex !== -1 ? compObject.Latitude[firstIndex] : null,
        longitude: firstIndex !== -1 ? compObject.Longitude[firstIndex] : null,
      };
    
      // Return a new array with the firstLatLong added at the beginning
      return [firstLatLong, ...latLongs];
      
    }, [savedComps, compObject, memoizedSelectedComps, negotiationCompsValues, view]);
    

    const regularAvg = useMemo(() => {
      const regularAvg = regularComps.adj_price.slice(0, 5).reduce((acc, price) => acc + price, 0) / 5;
      return regularAvg;
    }, [comp, regularComps]);


    // UPDATE THIS so that the average is calculated in the toolbar instead of here?
    // can break this function up to memoize the value individuall (specifically for saved comps this would be smart)
    const compAvg = useMemo(() => {

      if(view === 'negotiation'){
        // here, calculate the average based on the adjusted price values of negotiation
        // Here, map over negotiationCompsValues.adj_price and calculate average for selected comps
        const negotiationAvg = negotiationCompsValues.adj_price.reduce((acc, price) => acc + price, 0) / negotiationCompsValues.adj_price.length;
        return {
          selectedComps: negotiationAvg, // calc this for real?
        }
      }

      // If you are in negotiation, need to calculate avg based on the comps in the grid themselves.
      const calculateAverage = (compIds) => {
        const compPrices = compIds.map((parcelId) => {
          const index = comps.parcel_id.indexOf(parcelId);
          return index !== -1 ? comps.adj_price[index] : 0;
        });
        if (compPrices.length === 0) return "None selected";
        const sum = compPrices.reduce((acc, price) => acc + price, 0);
        return sum / compPrices.length;
      };
    
      // Calculate the average based on memoizedSelectedComps or fallback to savedComps
      // console.log(memoizedSelectedComps)
      const selectedAverage =
        Array.isArray(memoizedSelectedComps)
          ? calculateAverage(memoizedSelectedComps)
          : savedComps.length > 0
          ? calculateAverage(savedComps)
          : 'None selected'
          // "None selected"
          
      const savedAverage = savedComps.length > 0
          ? calculateAverage(savedComps)
          : 'None selected';
    
      // Calculate top5 average
      const top5Comps = comps.adj_price.slice(0, 5);
      const top5Average =
        top5Comps.length > 0
          ? top5Comps.reduce((acc, price) => acc + price, 0) / top5Comps.length
          : 0;
    
      // Return the object
      return {
        selectedComps: selectedAverage,
        savedComps: savedAverage,
        top5: top5Average,
      };
    }, [comps, memoizedSelectedComps, savedComps, view]);

    const handleSelectAllChange = useCallback((params) => { // this function doesn't really do anything it could be fully internal in headers.
      // update your internal datagrid state (this is a little hacky) to be cleared out.
      console.log('removing selected comps')
      console.log(params)
      const apiRef = params.colDef.apiRef.current
      // console.log(apiRef)
      // here, have it go through the selectionArray and set selected to false, then clear rray
      // const selectedRowIds = params.colDef.selectionArray || [];

      // Update each row and insert in order based on updatedSelectedRowIds
      // selectedRowIds.forEach((rowId, index) => {
      //   const row = rows.find(r => r.parcel_id === rowId); // Find the row based on rowId
      //   if (row) {
      //     const updatedRow = {
      //       ...row,
      //       isSelected: false, // Set `isSelected` based on the new array
      //       rowIndexPosition: 0, // Use index + 1 to maintain the order
      //       selectionArray: [],
      //       selected: false,
      //     };
      //     console.log(updatedRow)
      //     apiRef.current.updateRows([updatedRow]); // Update row in the grid
      //   }
      // });
      // console.log(apiRef)
      params.colDef.indeterminate = false
      params.colDef.selectionArray = []
      const column = apiRef.getColumn('actions')
      setSelectedComps([])
      setRowUpdate(!memoizedRowUpdate) // force a state update at lower level to clear out its local state in addition to this interal grid state.
      apiRef.updateColumns([column]);

    },[memoizedRowUpdate, setRowUpdate]);

    // console.log(subject)

    const createSubjectRows = () => {
      // Construct and return a single object instead of an array
      return {
        "#": '',
        // index: comp,
        PropertyInfo: {
          Address: subject.Address,
          parcel_id: subject.parcel_id,
          SDName: subject.SDName,
          SDCode: subject.SDCode,
          Town: subject.Town,
          Zip: subject.ZipCode,
        },
        parcel_id: subject.parcel_id,
        Town: subject.Town,
        ZipCode: subject.ZipCode,
        NeighborhoodCode: subject?.NeighborhoodCode || '',
        // If you want you can add in settlement information here.
        Name: caseObject?.Name || '',
        ScarIndex: caseObject?.SCARIndexNumber || '',
        PropertyAssessment: subject.AssessmentVillage !=='' && village!=='All' ? subject.AssessmentVillage : subject.PropertyAssessment,
        PCC: subject.PCC,
        // View needs to be updated to add other fields w/ a dict.
        View: subject.Waterfront === 1 ? 'Waterfront' : subject.Waterview === 1 ? 'Waterview' : '',
        SDName: subject.SDName,
        SDCode: subject.SDCode,
        Style: subject.Style,
        YearBuilt: subject.YearBuilt,
        Sqft: subject.Sqft,
        EstSqft: subject?.RegridConvertedSqFt || '',
        Acres: subject.Acres,
        fullBaths: subject.Bath,  // Use correct key
        halfBaths: subject.HalfBath,  // Use correct key
        totalBaths: subject.Bath + (subject.HalfBath / 2),  // Use correct keys
        IFMV: village !== 'All' ? subject.IFMV_Village : subject.IFMV,
        SalePrice: subject.SalePrice,
        MLSNumber: subject.MLSNumber,
        SaleDate: subject.SaleDate,
        adj_price: subject.adj_price,
        time_adj: subject.time_adj || '',
        Condition: subject.ConditionIn || '',
        Grade: subject.Grade || '',
        Pool: subject.InGroundPool === 1 ? 'True' : '',
        // FinishedBasement: subject.FinishedBasement || '',
        Distance: subject.Distance,
        RepId: subject.RepID ?? '',
        Deck: subject.Deck,
        Porch: subject.Porch,
        Fireplaces: subject.Fireplaces,
        Kitchens: subject.Kitchens,
        FinishedBasement: subject?.FinishedBasement || '', // this is only going to exist IF the data exists.
        CentralAir: subject.CentralAir,
        Heating: subject.Heating,
        ContractDate: subject.ContractDate,
        Patio: subject.Patio,
        TotalRooms: subject.TotalRooms,
        Remarks: subject.Remarks,
      };
    };

    // console.log(comps)

    // Eventually fix this totally stupid function you've adapted to be duplicaed for no good reason.
    const createRows = () => {
      if (view === 'negotiation') {
        // Generate rows based on `comps`
        const rows = Object.keys(comps.Address || {}).map((key, index) => ({
          id: index,
          '#': index + 1,
          PropertyInfo: {
            Address: comps.Address[key] || '',
            parcel_id: comps.parcel_id[key] || '',
            SDName: comps.SDName[key] || '',
            SDCode: comps.SDCode[key] || '',
          },
          parcel_id: comps.parcel_id[key] || '',
          Town: comps.Town[key] || '',
          ZipCode: comps.ZipCode[key] || '',
          NeighborhoodCode: comps?.NeighborhoodCode?.[key] || '',
          PropertyAssessment: comps.PropertyAssessment[key] || '',
          PCC: comps.PCC[key] || '',
          View: comps.Waterfront?.[key] === 1
            ? 'Waterfront'
            : comps.Waterview?.[key] === 1
            ? 'Waterview'
            : '',
          SDName: comps.SDName[key] || '',
          SDCode: comps.SDCode[key] || '',
          Style: comps.Style[key] || '',
          YearBuilt: comps.YearBuilt[key] || '',
          Sqft: comps.Sqft[key] || '',
          EstSqft: comps?.RegridConvertedSqFt?.[key] || '',
          Acres: comps.Acres[key] || '',
          fullBaths: comps.Bath[key] || '',
          halfBaths: comps.HalfBath[key] || '',
          totalBaths: (comps.Bath[key] || 0) + (comps.HalfBath[key] || 0) / 2,
          IFMV: comps.IFMV[key] || '',
          SalePrice: comps.SalePrice[key] || '',
          SaleDate: comps.SaleDate[key] || '',
          MLSNumber: comps.MLSNumber[key] || '',
          adj_price: comps.adj_price[key] || '',
          time_adj: comps.time_adj?.[key] || '',
          Pool: comps.InGroundPool[key] === 1 ? 'True' : '',
          Condition: comps.ConditionIn[key] || '',
          Grade: comps.Grade[key] || '',
          Distance: comps.Distance[key] || '',
          selected: savedComps.includes(comps.parcel_id[key]),
          isSelected: savedComps.includes(comps.parcel_id[key]),
          Deck: comps.Deck[key] || '',
          Porch: comps.Porch[key] || '',
          Heating: comps.Heating[key] || '',
          Fireplaces: comps.Fireplaces[key] || '',
          Kitchens: comps.Kitchens?.[key] || '',
          Patio: comps.Patio[key] || '',
          FinishedBasement: comps.FinishedBasement?.[key] || '',
          CentralAir: comps.CentralAir[key] || '',
          ContractDate: comps.ContractDate[key] || '',
          TotalRooms: comps.TotalRooms[key] || '',
          Remarks: comps.Remarks[key] || '',
        }));
    
        // Always add a blank row at the end to display the "add row" line to the user.
        rows.push({
          id: rows.length,
          '#': rows.length + 1,
          PropertyInfo: {
            Address: '',
            parcel_id: '',
            SDName: '',
            SDCode: '',
          },
          parcel_id: '',
          Town: '',
          ZipCode: '',
          NeighborhoodCode: '',
          PropertyAssessment: '',
          PCC: '',
          View: '',
          SDName: '',
          SDCode: '',
          Style: '',
          YearBuilt: '',
          Sqft: '',
          EstSqft: '',
          Acres: '',
          fullBaths: '',
          halfBaths: '',
          totalBaths: '',
          IFMV: '',
          SalePrice: '',
          SaleDate: '',
          MLSNumber: '',
          adj_price: '',
          time_adj: '',
          Distance: '',
          selected: false,
          isSelected: false,
          Pool: '',
          Condition: '',
          Grade: '',
          Deck: '',
          Porch: '',
          Heating: '',
          Fireplaces: '',
          Kitchens: '',
          Patio: '',
          FinishedBasement: '',
          CentralAir: '',
          ContractDate: '',
          TotalRooms: '',
          Remarks: '',
        });
    
        return rows;
      }
    
      // Default behavior for other views (as comps is an object)
      return Object.keys(comps.Address || {}).map((key, index) => ({
        id: index,
        '#': index + 1,
        PropertyInfo: {
          Address: comps.Address[key] || '',
          parcel_id: comps.parcel_id[key] || '',
          SDName: comps.SDName[key] || '',
          SDCode: comps.SDCode[key] || '',
        },
        parcel_id: comps.parcel_id[key] || '',
        Town: comps.Town[key] || '',
        ZipCode: comps.ZipCode[key] || '',
        NeighborhoodCode: comps?.NeighborhoodCode?.[key] || '',
        PropertyAssessment: comps.PropertyAssessment[key] || '',
        PCC: comps.PCC[key] || '',
        View: comps.Waterfront?.[key] === 1
          ? 'Waterfront'
          : comps.Waterview?.[key] === 1
          ? 'Waterview'
          : '',
        SDName: comps.SDName[key] || '',
        SDCode: comps.SDCode[key] || '',
        Style: comps.Style[key] || '',
        YearBuilt: comps.YearBuilt[key] || '',
        Sqft: comps.Sqft[key] || '',
        // regrid estimated sqft to be the same as the subject
        EstSqft: comps?.RegridConvertedSqFt?.[key] || '',
        Acres: comps.Acres[key] || '',
        fullBaths: comps.Bath[key] || '',
        halfBaths: comps.HalfBath[key] || '',
        totalBaths: (comps.Bath[key] || 0) + (comps.HalfBath[key] || 0) / 2,
        IFMV: comps.IFMV[key] || '',
        SalePrice: comps.SalePrice[key] || '',
        SaleDate: comps.SaleDate[key] || '',
        MLSNumber: comps.MLSNumber[key] || '',
        // Here, IF the value of runtype is assessment, display adj_IFMV instead of adj_price
        adj_price: getReportType === 'assessment' ?  comps?.adj_IFMV?.[key] || '' : comps.adj_price[key] || '',
        time_adj: comps.time_adj?.[key] || '',
        Distance: comps.Distance[key],
        selected: savedComps.includes(comps.parcel_id[key]),
        isSelected: savedComps.includes(comps.parcel_id[key]),
        status: village !== 'All' ? comps.adj_price[key] > subject.IFMV_Village ? 'negative' : 'positive' : comps.adj_price[key] > subject.IFMV ? 'negative' : 'positive',
        Pool: comps?.InGroundPool?.[key] === 1 ? 'True' : '',
        Condition: comps?.ConditionIn?.[key] || '',
        Grade: comps?.Grade?.[key] || '',
        Deck: comps.Deck[key] || '',
        Porch: comps.Porch[key] || '',
        Heating: comps.Heating[key] || '',
        Fireplaces: comps.Fireplaces[key] || '',
        Kitchens: comps.Kitchens?.[key] || '',
        Patio: comps.Patio[key] || '',
        FinishedBasement: comps.FinishedBasement?.[key] || '',
        CentralAir: comps.CentralAir[key] || '',
        ContractDate: comps.ContractDate[key] || '',
        TotalRooms: comps?.TotalRooms?.[key] || '',
        Remarks: comps?.Remarks?.[key] || '',
      }));
    };
    
    // Updated this dependency so it updates if subject changes (by looking at 1st index of your object.)
    const subjectRow = useMemo(() => {
      return(
      createSubjectRows()
    )
    }, [Object.values(memoizedCompObject).map(value => value[0]).join(',')]);

    const memoizedSubject = useMemo(() => subjectRow, [JSON.stringify(subjectRow)]);

  // DIsplaly the view column if the subjct has a view
  const memoizedColumnVisibilityModel = useMemo(() => {
    // This programatically displays the view col
    const updatedModel = { ...columnVisibilityModel };
    // console.log('rerendering',memoizedSubject)
  if (memoizedSubject?.View && memoizedSubject.View !== "") {
    updatedModel.View = true;
  } 
  return updatedModel;
}, [memoizedSubject, columnVisibilityModel]);


// Result is your filtered headers.
const headerResult = useMemo(() => {
  return headers.filter((header) => {
    return memoizedColumnVisibilityModel[header.field] !== false;
  });
}, [headers, memoizedColumnVisibilityModel]);
// Do not filter here, creating a seperate filtered headers var
  const calculateFlexForHeaders = useCallback(() => {
    // did this the quick way for your demo, this will break on big enoguh screens (it will look dumb extended all the way out)
    // Use full screen window width, not from the ref:
    // 48 px of padding on left and right, and 2px of border = subtract 50 px.
    const containerWidth = document.documentElement.clientWidth - 50

    return calculateHeaders(headerResult, memoizedColumnVisibilityModel, containerWidth);
  }, [memoizedColumnVisibilityModel, headerResult]);

  const filteredHeaders = useMemo(() => calculateFlexForHeaders(), [calculateFlexForHeaders, view]);

    

    // You changed this to use the getNegotiation Obj, but it may be able to use the taxYear now that you fixed court selection bug.
    const handleSaveComps = useCallback(async (selectedRowIds) => {
      // console.log('checking notes')
      // console.log(memoizedCaseNotes)
      // If the user just updated the notes, then it saves with already existing comps.
      if(selectedRowIds.length === 0){
        selectedRowIds = savedComps
      }
      // you will also need to pass up any miscellaneous adjustments you create here.
      // save button doesn't disappear on reload? Might be a dev only issue.
      const userObject = JSON.parse(localStorage.getItem('userInfo'))
      const isArrayOfObjects = selectedRowIds.every(item => typeof item === 'object' && item !== null);
      
      // console.log(taxYear) // this was coming back as undefined?? why? I swapped to negotiationObj taxyear instead
      
      // If it's an array of objects, process it as before; otherwise, use it directly
      let compsObject = isArrayOfObjects
      ? selectedRowIds.reduce((acc, value, index) => {
          acc[index + 1] = value.parcel_id;
          return acc;
        }, {})
      : selectedRowIds.reduce((acc, value, index) => {
          acc[index + 1] = value;
          return acc;
        }, {});
  
      // Ensure compsObject keys are sequential
      compsObject = Object.values(compsObject).reduce((acc, value, index) => {
        acc[index + 1] = value; // Re-index the values to make them sequential
        return acc;
      }, {});

      // find the subjectRow.parcel_id in getNegotiationObj.cases array of objects
      const subjectRowIndex = getNegotiationObj.cases.findIndex(caseObj => caseObj.parcel_id === subject.parcel_id);
      // If a match is found, subjectRowIndex will be >= 0; if no match, subjectRowIndex will be -1
      // this flow will break the user out if they are a non-client.
      console.log('the index will be found in all instances now',subjectRowIndex)


      // COMMENTING OUT THE NONCLIENT FLOW FOR NOW -- idk if we care about it at all now. iF manually revieweing, can put themin our comps db.
      console.log('saved as regular comp because manual review')
      // if (subjectRowIndex !== -1) {
      //   // console.log('Found matching case at index:', subjectRowIndex);
      //   if (!getNegotiationObj.cases[subjectRowIndex].Client) {
      //     console.log('saving nonclient')
      //     // 
      //     const saveObject = {
      //       NonClientFlag: 1,
      //       Cases: [
      //         {
      //           Subject: subjectRow.parcel_id,
      //           Comps: compsObject,
      //           // CaseNotes: caseNotesRef.current,
      //           CaseNotes: memoizedCaseNotes,
      //         },
      //       ],
      //       VillageFlag: village!=='All' ? 1 : 0,
      //       TaxYear: getNegotiationObj.TaxYear,
      //     }

      //     console.log('params going into fetch',saveObject)

      //     try{
      //       const saveNonClientResponse = await axiosInstance.post('/save_non_client_comps', saveObject);
      //       console.log(saveNonClientResponse)
      //       toast.success('Comps saved successfully (nonclient).')
      //     }
      //     catch(err){
      //       console.log(err)
      //       toast.error('There was an error saving the comps.')
      //     }
      //     const updatedNegotiationObj = {...getNegotiationObj}; // set this equal t
      //     updatedNegotiationObj.cases[comp].Comps = compsObject
      //     const today = new Date();
      //     updatedNegotiationObj.cases[comp].RunDate = today.toUTCString();
      //     console.log(updatedNegotiationObj)
      //     // setNegotiationObj(updatedNegotiationObj)
      //     // if()
      //     setIsSaving(false)
      //     setRowUpdate(!rowUpdate)
      //     return
      //     // getNegotiationObj.cases[subjectRowIndex].Comps = compsObject;
      //   }
      // } 

      const offerValue = memoizedOfferAmount !== null ? Math.round(memoizedOfferAmount) : null;

      console.log('casenotes going into save',memoizedCaseNotes)
      // console.log('casenoteref going into save',caseNotesRef.current)
      const saveObject = {
        ManualReviewFlag: 1, 
        ManualOverride: 1, // this may only be relevant in the saveall version of function?
        Cases: [ // pass in as an object so the function works to be modular to save all too.
          {
          Subject: subjectRow.parcel_id,
          Comps: compsObject,
          CompAdjustments: memoizedSelectedAdjustments, // this was saving without adj
          OfferValue: offerValue,
          // include comps adjustments in comp Object? what is structure?
          // CaseNotes: caseNotesRef.current,
          CaseNotes: memoizedCaseNotes,
          RepId: subjectRow.RepId !== "" ? parseInt(subjectRow.RepId, 10) : null,
          },
        ],
        UserId: userObject?.userId || null,
        VillageFlag: village!=='All' ? 1 : 0,
        TaxYear: getNegotiationObj.TaxYear,
      }

      // console.log('your saveobject')
      // console.log(saveObject)
      // return // REMOVE THIS BEFORE DEPLOYING

      const newComps = await saveComps(saveObject)
      // console.log(newComps) // make sure u can properly return whatever value. (can just be t/f for erro rhandling)
      // apiRef.current.updateColumns([column]);
      const updatedNegotiationObj = {...getNegotiationObj}; // set this equal t
      updatedNegotiationObj.cases[comp].Comps = compsObject;
      // updatedNegotiationObj.cases[comp].CaseNotes = caseNotesRef.current
      updatedNegotiationObj.cases[comp].CaseNotes = memoizedCaseNotes;
      // Make it so the offervalue gets set when the user saves comp
      updatedNegotiationObj.cases[comp].OfferValue = offerValue;
      const today = new Date();
      updatedNegotiationObj.cases[comp].RunDate = today.toUTCString();
      
      console.log(updatedNegotiationObj)
      // console.log('saved as regular comp because manual review')
      // console.log('setting saving false')
      setIsSaving(false)
      setNegotiationObj(updatedNegotiationObj)
      // do you need both???
      setRowUpdate(!rowUpdate)

      if(caseNotesChangedRef.current){
        setCaseNotesChanged(false);
      }
      // setRowUpdate(!memoizedRowUpdate) // force a state update at lower level to clear out its local state in addition to this interal grid state.
    }, [subjectRow, rowUpdate, memoizedCaseNotes, memoizedSelectedAdjustments, taxYear, caseNotesChangedRef.current, savedComps, memoizedOfferAmount]);

    // memoize rows
    const rows = useMemo(() => createRows(), [comps, rowUpdate]);

    // console.log('issaving in case review',isSaving)
//   LOOK INTO NEW MAPPING LIBRARY HERE - see if you ccan also impose a side streetview partition in the
    return (
      <div className='h-full'>

        {displayModal && 
        // remove this from here, it shoul dbe its own component.
        <MiscAdjustmentModal
        toggleDisplayModal={toggleDisplayModal}
        parcelId={subjectRow.parcel_id}
        selectedComps={memoizedSelectedComps || savedComps}
        handleAddAdjustment={handleAddAdjustment}
        // if selectedcomps then this, otherwise saved.
        >
        </MiscAdjustmentModal>
        }

        {/* here, based on if map is rendered, render the table in diff sizes. (just do classname update) */}
        <div className='h-full flex flex-col'>
          {/* <div className='flex-grow'>
          </div> */}

          {compObjectToDisplay ?
          <PropertyInfoPage
          compObject={compObjectToDisplay}
          subject={compObjectToDisplay.subject}
          handleCompViewClose={handleCompViewClose}
          handleProcessDBUpdate={handleProcessDBUpdate}
          handleSetSaving={handleSetSaving}
          >

          </PropertyInfoPage>
          :        
        <Map
        // Redo this, passing inteh subject Lat / long, and comp lat / long (can just pass int he object for now?)
          latLongs={memoizedLatLongs}
        />
        }

        {/* render the map here, passing in the subject, and the comps (some # of comps) */}
        {/* here, will setting casenotes cause a whole re-render fuckfest? */}
        <div className='h-1/2'>
          <TableCustomToolbar 
          compAvg={compAvg}
          caseNotes={memoizedCaseNotes}
          changeCaseNotes={handleChangeNotes}
          view={view}
          offerAmount={memoizedOfferAmount}
          subject={subject}
          handleProcessDBUpdate={handleProcessDBUpdate}
          // handleOfferUpdate={handleOfferUpdate}
          handleUpdateStateCallback={handleUpdateStateCallback}
          // Set rundate to today if it doesn't exist
          runDate={caseObject?.RunDate || new Date().toLocaleDateString('en-US')}
          regularAvg={regularAvg}
          compsSelected={memoizedSelectedComps}
          savedComps={savedComps}
          viewCallback={viewCallback}
          globalCompRef={globalCompRef}
          // do yoiu need to memoize these selected comps? itll lrerender every time even if not bool flip.;
          />

          {/* Here, if the view is different, do NOT render the MUI table component */}
          {view==='negotiation'?
          <NegotiationTable
          rows={rows}
          updateNegotiationComps={updateNegotiationComps}
          headers={headerResult}
          filteredHeaders={filteredHeaders}
          handleDeleteProperty={handleDeleteProperty}
          subject={memoizedSubject}
          tableProps={tableProps}
          handleSetPropertyInfo={handleSetPropertyInfo}
          unsavedChangesRef={unsavedChangesRef}
          handleAddressClick={handleAddressClick}
          >
          </NegotiationTable>
          
          : // If in the other views, render regular table.
          // DEFAULT TABLE RENDERING (for analysis + court tab)
          <MuiTable 
            subject={memoizedSubject}
            addCompNotInResponseCallback={addCompNotInResponseCallback}
            handleSetPropertyInfo={handleSetPropertyInfo}
            compStreamObject={compStreamObject}
            selectedComps={memoizedSelectedComps}
            handleCaseNotesCleanup={handleCaseNotesCleanup}
            handleSaveComps={handleSaveComps}
            originalRows={rows} // These may need to be memoized
            view={view}
            savedCompPids={savedComps}
            headers={headers}
            toggleDisplayModal={toggleDisplayModal}
            tableProps={tableProps}
            adjustmentRef={adjustmentRef}
            updateSelectedComps={handleUpdateSelectedComps}
            caseNotesChanged={caseNotesChanged}
            handleProcessDBUpdate={handleProcessDBUpdate}
            unsavedChangesRef={unsavedChangesRef}
            isSaving={!isUpdated || isSaving}
            setIsSaving={handleSetSaving}
            memoizedColumnVisibilityModel={memoizedColumnVisibilityModel}
            handleColumnVisibilityChange={handleColumnVisibilityChange}
            filteredHeaders={filteredHeaders}
          >
          </MuiTable>
          }
      </div>
    </div>
      </div>
    );
};
// CaseReview.whyDidYouRender = true;
export default CaseReview