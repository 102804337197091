import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import { usePersistedDataStore } from "../../store";
import { toast } from "react-toastify";

const TimeAdjDialog = ({ open, onClose, onSave }) => {
  const setTimeAdjustmentSettings = usePersistedDataStore((state) => state.setTimeAdjustmentSettings);
  const getTimeAdjustmentSettings = usePersistedDataStore((state) => state.timeAdjustmentSettings);
  // console.log(getTimeAdjustmentSettings)
  const [adjustmentValue, setAdjustmentValue] = useState(getTimeAdjustmentSettings?.timeAdjustmentValue || "");
  const [dateType, setDateType] = useState(getTimeAdjustmentSettings?.saleDateType || "title");

  const handleSave = () => {
    // console.log('adj value', adjustmentValue);
    // console.log('date type', dateType);
    // console.log(getTimeAdjustmentSettings)
    const updateTimeAdj = {...getTimeAdjustmentSettings};
    updateTimeAdj.timeAdjustmentValue = adjustmentValue;
    updateTimeAdj.saleDateType = dateType;
    setTimeAdjustmentSettings(updateTimeAdj);

    toast.success("Time Adjustment settings saved successfully!");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{zIndex: 0}}>Time Adjustment</DialogTitle>
      <DialogContent
        sx={{
          overflow: "visible", // Prevent clipping
        }}>
        <TextField
          label="Annual Time Adjustment (%)"
          type="number"
          fullWidth
          sx={{
            "& .MuiInputBase-root": {
              overflow: "visible", // Prevent input content from clipping
              zIndex: '10 !important', // Prevent input content from clipping
            },
          }}
          // size="small"
          value={adjustmentValue}
          onChange={(e) => setAdjustmentValue(e.target.value)}
        />
        <RadioGroup
          value={dateType}
          onChange={(e) => setDateType(e.target.value)}
          row
        >
          {/* may not need generic if saledate = title date */}
          {/* <FormControlLabel value="generic" control={<Radio />} label="Sale Date" /> */}
          <FormControlLabel value="title" control={<Radio />} label="Title Date" />
          <FormControlLabel value="contract" control={<Radio />} label="Contract Date" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeAdjDialog;
