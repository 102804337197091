import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

const AVTextField = ({ value, label = "AV", showAVs = false, getRar = 1, isHidden = false, adornmentSymbol = "$" }) => {
  const formattedValue =
    typeof value === "string"
      ? value
      : showAVs
      ? Math.round(value * getRar).toLocaleString()
      : Math.round(value).toLocaleString();

  return (
    <div className="flex flex-col justify-center relative w-full">
      <TextField
        value={formattedValue}
        variant="outlined"
        className="group"
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment
              position="end"
              className="relative flex items-center"
              sx={{
                height: "22px", // Match the height of the input field
                marginTop: "0px",
                marginBottom: "0px",
                alignSelf: "center",
              }}
            >
              <div className="flex items-center relative">
                <span className="z-10">{adornmentSymbol}</span>
              </div>
            </InputAdornment>
          ),
        }}
        autoComplete="off"
        sx={{
          margin: 0,
          padding: 0,
          "& .MuiInputBase-root": {
            display: "flex",
            height: "36px", // Adjust height for alignment
            maxWidth: "135px",
            alignItems: "center",
            padding: "0px",
          },
          "& input": {
            textAlign: "right",
            padding: "0px",
            minWidth: "40px",
            width: `${
              typeof value === "string"
                ? 110
                : showAVs
                ? Math.max(Math.round(value * getRar).toString().length * 10, 40)
                : Math.max(Math.round(value).toString().length * 10, 40)
            }px`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
      <span
        className="w-full"
        style={{
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          fontSize: "11px",
          pointerEvents: "none",
          textAlign: "center",
        }}
      >
        <span className={`font-normal ${isHidden ? "hidden" : ""}`}>
          {Math.round(value * getRar).toLocaleString()} {label}
        </span>
      </span>
    </div>
  );
};

export default AVTextField;
