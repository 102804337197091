import React, { useState } from 'react';
import { useCompStore, useLocalDataStore } from '../store';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { toast } from 'react-toastify';


const AdjustmentsDialog = ({ open, onClose }) => {
  const getAdjustments = useCompStore((state) => state.adjustments);
  const setAdjustments = useCompStore((state) => state.setAdjustments);
  // const [adjustmentsUpdated, setAdjustmentsUpdated] = useState(false);
  const adjustmentsUpdated = useLocalDataStore((state) => state.settingsUpdated)
  const setAdjustmentsUpdated = useLocalDataStore((state) => state.setSettingsUpdated)
  console.log(adjustmentsUpdated)

  const displayLabels = {
    Acres: 'Land Area (Acres)',
    Sqft: 'Living Area (SqFt)',
    LotSqFt: 'Lot Area (SqFt)',
    Bath: 'Bathrooms (Full)',
    HalfBath: 'Bathrooms (Half)',
    FinishedBasement: 'Finished Basement SqFt',
    BasementSqFtUnfinished: 'Unfinished Basement SqFt',
    FullFinished: 'Full Finished Basement',
    FullPartialFinish: 'Partial Finished Basement',
    FullUnfinished: 'Full Unfinished Basement',
    Bulkhead: 'Bulkhead',
    CentralAir: 'Central Air Conditioning',
    Crawl: 'Crawl Space',
    Deck: 'Deck',
    Patio: 'Patio',
    Porch: 'Porch',
    InGroundPool: 'In-ground Pool',
    PoolAg: 'Above Ground Pool',
    PoolUnsure: 'Unspecified Pool',
    GarageDet: 'Detached Garage',
    TotalGarageCap: 'Garage Capacity',
    Fireplaces: 'Fireplaces',
    Kitchens: 'Kitchens',
    ConditionIn: 'Condition Adj (%)',
    Grade: 'Grade Adj (%)',
  };

  const adjustmentGroups = {
    GeneralFeatures: ['Acres', 'Sqft', 'LotSqFt', 'Bath', 'HalfBath', 'InGroundPool'],
    Basements: ['FinishedBasement', 'BasementSqFtUnfinished', 'FullFinished', 'FullPartialFinish', 'FullUnfinished'],
    Amenities: [
      'Bulkhead',
      'CentralAir',
      'Crawl',
      'Deck',
      'Patio',
      'Porch',
      'PoolAg',
      'PoolUnsure',
      'GarageDet',
      'TotalGarageCap',
      'Fireplaces',
      'Kitchens',
    ],
    Misc: ['ConditionIn', 'Grade'],
  };

  const percentageFields = ['ConditionIn', 'Grade'];
  const [localAdjustments, setLocalAdjustments] = useState(getAdjustments);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeKey, setActiveKey] = useState(null); // Tracks the active key for dropdown

  const handleMenuOpen = (event, key) => {
    setMenuAnchor(event.currentTarget);
    setActiveKey(key); // Set the active field for the menu
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveKey(null);
  };

  const handleMenuSelect = (option) => {
    if (option === 'IFMV Linked') {
      setLocalAdjustments((prev) => ({
        ...prev,
        [activeKey]: 'IFMV Linked',
      }));
    } else {
      setLocalAdjustments((prev) => ({
        ...prev,
        [activeKey]: 0,
      }));
    }
    if(!adjustmentsUpdated){setAdjustmentsUpdated(true);}
    handleMenuClose();
  };

  const handleInputChange = (key, value) => {
    const numericValue = parseFloat(
      (typeof value === 'string' ? value.replace(/,/g, '') : value) || 0
    );
    setLocalAdjustments((prev) => ({
      ...prev,
      [key]: numericValue,
    }));
    if(!adjustmentsUpdated){setAdjustmentsUpdated(true);}
  };
  

  const handleSave = () => {
    const updatedAdjustments = {
      ...localAdjustments,
      userUpdated: true,
    };

    console.log('Updated Adjustments:', updatedAdjustments);
    toast.success('Adjustments updated successfully!');
    setAdjustments(updatedAdjustments);
    setAdjustmentsUpdated(false);
    onClose();
  };


  return (
    // <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
    //   <DialogTitle>Edit Adjustments</DialogTitle>
    //   <DialogContent
    //     sx={{
    //       overflow: 'visible',
    //       display: 'flex',
    //       flexDirection: 'column',
    //       gap: 4,
    //     }}
    //   >
    <div className='justify-between h-full'>
      <>
        {/* Generic input, and custom handling for SQFT input */}
        <Box display="flex" gap={4}>
          {['GeneralFeatures', 'Basements'].map((group) => (
            <Box key={group} flex={1}>
              <Typography variant="h6" gutterBottom>
                {group === 'GeneralFeatures' ? 'General Features' : 'Basements'}
              </Typography>
              <Grid container spacing={2}>
                {adjustmentGroups[group]
                  .filter((key) => Object.keys(localAdjustments).includes(key))
                  .map((key) => (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      {key === 'Sqft' ? (
                        <>
                          <TextField
                            fullWidth
                            label="Living Area (SqFt)"
                            value={
                              localAdjustments[key] === 'IFMV Linked'
                                ? 'IFMV Linked'
                                : localAdjustments[key].toLocaleString()
                            }
                            onChange={(e) => {
                              if (localAdjustments[key] !== 'IFMV Linked') {
                                handleInputChange(
                                  key,
                                  parseFloat(e.target.value.replace(/,/g, '')) || 0
                                );
                              }
                            }}
                            InputProps={{
                              startAdornment:
                                localAdjustments[key] !== 'IFMV Linked' ? (
                                  <InputAdornment position="start">$</InputAdornment>
                                ) : null,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={(e) => {
                                      // Anchor to the TextField itself
                                      setMenuAnchor(e.currentTarget.closest('.MuiFormControl-root'));
                                      setActiveKey(key);
                                    }}
                                    size="small"
                                    aria-label="open menu"
                                  >
                                    <ArrowDropDownIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            disabled={localAdjustments[key] === 'IFMV Linked'}
                            sx={
                              localAdjustments[key] === 'IFMV Linked'
                                ? { backgroundColor: '#f5f5f5' }
                                : {}
                            }
                          />
                          <Menu
                            anchorEl={menuAnchor}
                            open={Boolean(menuAnchor) && activeKey === key}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left", // Align with the left edge of the TextField
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left", // Align with the left edge of the TextField
                            }}
                            PaperProps={{
                              style: {
                                width: menuAnchor?.offsetWidth || "auto", // Match TextField width
                              },
                            }}
                          >
                            <MenuItem onClick={() => handleMenuSelect('IFMV Linked')}>
                              IFMV Linked
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuSelect('Custom Value')}>
                              Custom Value
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <TextField
                          fullWidth
                          label={key}
                          value={localAdjustments[key].toLocaleString()}
                          onChange={(e) => handleInputChange(key, e.target.value)}
                          variant="outlined"
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ))}
        </Box>

        {/* Amenity section */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Amenities
          </Typography>
          <Grid container spacing={2}>
            {adjustmentGroups.Amenities.filter((key) =>
              Object.keys(localAdjustments).includes(key)
            ).map((key) => (
              <Grid item xs={12} sm={4} md={2} key={key}>
                <TextField
                  fullWidth
                  label={displayLabels[key] || key}
                  value={localAdjustments[key].toLocaleString()}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                  inputProps={{ maxLength: 10 }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: !percentageFields.includes(key) ? (
                      <InputAdornment position="start">$</InputAdornment>
                    ) : null,
                    endAdornment: percentageFields.includes(key) ? (
                      <InputAdornment position="end">%</InputAdornment>
                    ) : null,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Misc section */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Misc
          </Typography>
          <div className='flex'>
            {adjustmentGroups.Misc.filter((key) =>
              Object.keys(localAdjustments).includes(key)
            ).map((key) => (
              <Grid item xs={12} sm={4} md={3} key={key}>
                <TextField
                  fullWidth
                  label={displayLabels[key] || key}
                  value={localAdjustments[key].toLocaleString()}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                  inputProps={{ maxLength: 10 }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: !percentageFields.includes(key) ? (
                      <InputAdornment position="start">$</InputAdornment>
                    ) : null,
                    endAdornment: percentageFields.includes(key) ? (
                      <InputAdornment position="end">%</InputAdornment>
                    ) : null,
                  }}
                  sx={{
                    maxWidth: percentageFields.includes(key) ? '120px' : '100%',
                    width: percentageFields.includes(key) ? '100%' : 'auto',
                    marginRight: '8px',
                  }}
                />
              </Grid>
            ))}
            </div>
            
        </Box>
        </>

        {/* Only display these IF you are in the "in review" settings view */}
        <div className="absolute right-2 bottom-2 justify-end">
        <Button onClick={onClose} color="primary" className='mr-2'>
          Close
        </Button>
        <Button onClick={handleSave} disabled={!adjustmentsUpdated} color="primary" variant="contained">
          Save
        </Button>
        </div>
        </div>
  );
};

export default AdjustmentsDialog;
