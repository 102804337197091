import React from 'react';
import ExcelJS from 'exceljs';
import { usePersistedDataStore } from '../store';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import {CompReportGenerator} from './CompReportGenerator';
import LoadingOverlay from '../../common/LoadingOverlay';
import { PDFDocument } from 'pdf-lib';
import { showModalAndWaitForSelection} from '../../common/ShowModalAndWaitForSelection';

// {/* TURN THIS INTO A "hover" multiselect menu instead. */}
const DownloadButton = ({ caseData, fileName, keys, selectedRows  }) => {
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const getRar = usePersistedDataStore((state) => state.rar);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userName = userInfo?.given_name + ' ' + userInfo?.family_name || '';
  
  // console.log(caseData)
  // console.log(keys)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // This is your individual comp report saving function.
  // dropped this same function in here as a non component.
  const handlePrintCompReport = async ({compNumber, selectedRows}) => {
    const courtCase = getCompSheet[compNumber];
    const subject = courtCase.Address[0]

    try {
      // Return the PDF from generator function, and save as.
      const reportPDF = await CompReportGenerator({ compNumber, userName, selectedRows });
      saveAs(reportPDF, `${subject} Valuation.pdf`);
    } catch (error) {
      console.error("Error generating PDF report:", error);
      // Show a notification or alert here to inform the user about the error.
      toast.error("Error generating PDF report.");
    }
  };


  // HERE - update this function so that IF the user selects download XLS - it also runs the individual sales comp reports
  // BUT it doesn't download PDFS, it just generates them to push the value of the subjMarketValue from the report into the negotiation Obj
  // MAKE THIS OPTIONAL (in case you don't keep up with comp report changes)
  const handleOptionSelect = async (option) => {
    // setSelectedOption(option);
    handleClose(); // Close the menu after selection
    
    if (option === 'One_Pager_Individual') {
      console.log('One Pager Individual');
      setLoading(true);
      
      // Create an array of arrays, where each inner array contains the comps for each caseItem
      let selectedComps = caseData.map((caseItem) => Object.values(caseItem.Comps));
      console.log(selectedComps)

      // If selectedRows has indices, filter selectedComps to include only those indices
      // enable ability for user to select and print individuals
      if (selectedRows.length > 0) {
        selectedComps = selectedRows.map((index) => selectedComps[index]).filter(Boolean);
        console.log(selectedComps)
        for (let index=0; index< selectedRows.length; index++) {
          const compNumber = selectedRows[index]; // Use the value from selectedRows as compNumber
          const selectedComp = selectedComps[index]; // Get the corresponding comps using index
          await handlePrintCompReport({ compNumber, selectedRows: selectedComp });
        }
      }else{
      for (let i = 0; i < selectedComps.length; i++) {
        // Call the print report function
        await handlePrintCompReport({ compNumber: i, selectedRows: selectedComps[i] });
        setProgress(i + 1); // Update to current progress
      }
      }

    setLoading(false); // Stop loading
    return
    }
    else if (option === 'One_Pager_Batched') {
      console.log('One Pager Batched');
      setLoading(true);
      const pdfDocs = [];

      // Create an array of arrays, where each inner array contains the comps for each caseItem
      let selectedComps = caseData.map((caseItem) => Object.values(caseItem.Comps));

      try {
        // Iterate over your length of the caseData.length
        if (selectedRows.length > 0) {
          selectedComps = selectedRows.map((index) => selectedComps[index]).filter(Boolean);
          console.log(selectedComps)
          for (let index=0; index< selectedRows.length; index++) {
            const compNumber = selectedRows[index]; // Use the value from selectedRows as compNumber
            const selectedComp = selectedComps[index]; // Get the corresponding comps using index
            const reportPDF = await CompReportGenerator({ compNumber, userName, selectedRows: selectedComp });
            const pdfDoc = await PDFDocument.load(await reportPDF.arrayBuffer());
            setProgress(index + 1); // Update to current progress
            pdfDocs.push(pdfDoc);
          }
        }else{
        for (let i = 0; i < selectedComps.length; i++) {
          const reportPDF = await CompReportGenerator({ compNumber: i, userName, selectedRows: selectedComps[i] });
          const pdfDoc = await PDFDocument.load(await reportPDF.arrayBuffer());
          setProgress(i + 1); // Update to current progress
          pdfDocs.push(pdfDoc);
        }
        }


        // Create a new PDF document
        const mergedPdf = await PDFDocument.create();

        // Copy pages from each individual PDF into the merged PDF
        for (const pdfDoc of pdfDocs) {
          const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
          copiedPages.forEach((page) => mergedPdf.addPage(page));
        }

        // Serialize the merged PDF document to bytes
        const pdfBytes = await mergedPdf.save();

        // Save the merged PDF
        saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), `${fileName}.pdf`);
      } catch (error) {
        console.error("Error generating batched PDF report:", error);
        toast.error("Error generating batched PDF report.");
      } finally {
        setLoading(false); // Stop loading
      }
      return
    }
    else if (option === 'PDF_FNMA') {
      console.log('PDF FNMA');
      // Create an array of arrays, where each inner array contains the comps for each caseItem
      let selectedComps = caseData.map((caseItem) => Object.values(caseItem.Comps));
      console.log(selectedComps);

      // Call the print report function
      // await handlePrintCompReport({ compNumber: 0, selectedRows: selectedComps });
      return
    }
    // Perform filtering for 'Scar Only' selection
    if (!['One_Pager_Individual', 'One_Pager_Batched', 'PDF_FNMA'].includes(option)) {
      const selectedOption = await showModalAndWaitForSelection(
        'Select Option',
        'Do you want to include internal Case Notes in this download?',
        'Yes',
        'No'
      );
  
      let filteredCases;
  
      // Check the user's selection
      if (selectedOption === 'Yes') {
        filteredCases = option === 'Scar_Only' 
          ? caseData.filter(caseItem => 
              caseItem.SCARFiled === 1 || 
              (caseItem.RepID && caseItem.RepID !== "" && caseItem.RepID !== null)
            )
          : caseData;
      } else {
        filteredCases = option === 'Scar_Only' 
          ? caseData
              .filter(caseItem => 
                caseItem.SCARFiled === 1 || 
                (caseItem.RepID && caseItem.RepID !== "" && caseItem.RepID !== null)
              )
              .map(caseItem => ({ ...caseItem, CaseNotes: '' }))
          : caseData.map(caseItem => ({ ...caseItem, CaseNotes: '' }));
      }
      // Proceed to download the filtered cases
      downloadXLS(filteredCases, option);
    } else {
      // Default case: download all cases
      downloadXLS(caseData, option);
    }
  };
  // caseData.Comps

  const downloadXLS = async (dataToDownload, option) => {

    // Unified configuration for each column
    const columnConfig = {
      'PctReduction': { format: '0.00%', width: 10, header: 'Pct Reduction' },
      'PropertyAssessment': { format: '#,##0', width: 10, header: 'Town Assessment' },
      'IFMV': { format: '#,##0', width: 12, header: 'Town Eq Value' },
      'SalePrice': { format: '#,##0', width: 12, header: 'Sale Price' },
      'Taxes': { format: '#,##0', width: 10, header: 'Estimated Taxes' },
      'SubjectMarketValue': { format: '#,##0', width: 12, header: 'Aventine Workup Value' },
      'OfferValue': { format: '#,##0', width: 12, header: 'Aventine Offer' },
      'RequestedAssessment': { format: '#,##0', width: 10, header: 'Requested Assessment' },
      'Name': { width: 18, header: 'Full Name' },
      'PropertyTown': {},
      'PropertyZip': {},
      'RunDate': {},
      // Additional columns without specific formats or widths
      'PID': {},
      'Comp1': { width: 25, header: "Comp 1" },
      'Comp2': { width: 25, header: "Comp 2" },
      'Address': {},
      'Municipality': {},
      'SCARIndexNumber': { header: 'Index Number' },
      'RAR': {},
      'SaleDate': {},
      'RecentSale': {},
      'settled': {},
      'CaseNotes': {},
      'RepID': {},
    };
  
    // Filter and reorder data
// Filter and reorder data
const reorderedData = dataToDownload.map((item, index) => {
  const reorderedItem = {};
  const compSheetItem = getCompSheet.find((sheetItem) => sheetItem.parcel_id[0] === item.parcel_id);

  const compPIDs = Object.values(item.Comps || {});
  const addresses = compPIDs.map((pid) => {
    const compSheetItem = getCompSheet.find((sheetItem) => sheetItem.parcel_id.includes(pid));
    if (compSheetItem) {
      const index = compSheetItem.parcel_id.indexOf(pid);
      return compSheetItem.Address[index] || "";
    }
    return "";
  });
  // console.log(addresses)
  // Add Address1 and Address2 columns
  item.Comp1 = addresses[0] || ""; // First address
  item.Comp2 = addresses[1] || ""; // Second address

  for (const key of keys) {
    let value = item[key];
    if (key === 'RAR') {
      reorderedItem[key] = getRar;
    } else if (key === 'RequestedAssessment') {
      if (compSheetItem && compSheetItem.PropertyAssessment) {
        let requestedAssessment = item.SubjectMarketValue * compSheetItem.RAR[0];
        // console.log('requestedassessment', requestedAssessment);

        if (item.OfferValue) {
          // console.log('OfferValue', item.OfferValue);
          const offeredAssessment = item.OfferValue * compSheetItem.RAR[0];
          requestedAssessment = offeredAssessment;
        }

        if (compSheetItem.IFMV[0] > 450000 && requestedAssessment < 0.75 * compSheetItem.PropertyAssessment[0]) {
          item.SubjectMarketValue = 0.75 * compSheetItem.IFMV[0];
          requestedAssessment = 0.75 * compSheetItem.PropertyAssessment[0];
        }

        reorderedItem[key] = parseInt(requestedAssessment, 10);
      } else {
        reorderedItem[key] = ''; // Default if calculation isn't possible
      }
    } else if (key === 'IFMV') {
      reorderedItem[key] = Math.round(item.IFMV);
    } else if (key === 'RecentSale') {
      reorderedItem[key] = value === false ? '' : value;
    }
    else if (key === 'SubjectMarketValue') {
      reorderedItem.SubjectMarketValue = item.SubjectMarketValue || '';
    } else if (key === 'settled') {
      // Set settled to true if SCARDeterminationValue exists as a number, otherwise set it to an empty string
      reorderedItem[key] = typeof item.SCARDeterminationValue === 'number' ? true : '';
    } else if (key === 'PctReduction') {
      const requestedAssessment = reorderedItem['RequestedAssessment'];
      if (compSheetItem && compSheetItem.PropertyAssessment && requestedAssessment) {
        const percentReduction =
          (requestedAssessment - compSheetItem.PropertyAssessment[0]) /
          compSheetItem.PropertyAssessment[0];
        reorderedItem[key] = Math.trunc(percentReduction * 10000) / 100;
      } else {
        reorderedItem[key] = '';
      }
    }
    else {
      reorderedItem[key] = value; // Default behavior for other keys
    }
  }

  return reorderedItem;
});

console.log(reorderedData)

    // Create workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const filteredKeys = keys // remove the offervalue filtering to seperate market value from offer value
    // .filter(key => key !== 'OfferValue');
    // const reorderedKeys = [
    //   'PctReduction',
    //   'settled',
    //   'CaseNotes',
    //   ...keys.filter((key) => key !== 'PctReduction' && key !== 'settled' && key !== 'CaseNotes'),
    // ];
  
    // Set up columns using the keys and the unified configuration object
    worksheet.columns = filteredKeys.map(key => {
      const config = columnConfig[key] || {};
      return {
        header: config.header || key,
        key: key,
        width: config.width || undefined,
        style: { numFmt: config.format || undefined }
      };
    });
  
    // Add data rows to the worksheet
    reorderedData.forEach(data => worksheet.addRow(data));
  
    // Apply specific formats from columnConfig
    worksheet.columns.forEach(column => {
      const config = columnConfig[column.key] || {};
      const format = config.format;
      if (format) {
        column.eachCell((cell, rowNumber) => {
          if (rowNumber > 1) { // Skip the header row
            cell.numFmt = format;
            // Convert cell value if it's a percentage
            if (format === '0.00%' && cell.value) {
              cell.value = cell.value / 100;
            }
          }
        });
      }
    });
  
    // Set fixed width or autosize columns based on content length
    worksheet.columns.forEach(column => {
      const fixedWidth = columnConfig[column.key]?.width;
      if (fixedWidth) {
        column.width = fixedWidth;
      } else {
        // Autosize if no fixed width is defined
        const lengths = column.values.map(v => (v ? v.toString().length : 10));
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
        column.width = maxLength + 2; // Add padding for better readability
      }
    });
  
    // Write to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Trigger download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}_${option}.xlsx`;
    link.click();
  };
  

  return (
    <div>
      {loading && (
        <LoadingOverlay progress={progress}/>
      )}
        <Button
          id="demo-customized-button"
          // className='max-w-[120px]'
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          >
          Download
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <div style={{ padding: '0px 16px', fontWeight: 'bold' }}>XLS</div>
          <MenuItem onClick={() => handleOptionSelect('Scar_Only')}>Scar Only</MenuItem>
          <MenuItem onClick={() => handleOptionSelect('All_Cases')}>All Cases</MenuItem>
          
          <Divider />

          <div style={{ padding: '0px 16px', fontWeight: 'bold' }}>PDF</div>

          {/* TURN THIS INTO A "hover" multiselect menu instead. */}
          {/* One Pagers Header */}
          <div style={{ padding: '4px 16px', fontWeight: 'bold', fontSize: '14px' }}>One Pagers</div>
          <MenuItem onClick={() => handleOptionSelect('One_Pager_Individual')}>Individual</MenuItem>
          <MenuItem onClick={() => handleOptionSelect('One_Pager_Batched')}>Batched</MenuItem>
          <div style={{ padding: '4px 16px', fontWeight: 'bold', fontSize: '14px' }}>FNMA</div>
          <MenuItem onClick={() => handleOptionSelect('PDF_FNMA')} disabled={true}>Individual</MenuItem>
          <MenuItem onClick={() => handleOptionSelect('PDF_FNMA')} disabled={true}>Batched</MenuItem>
        </Menu>
    </div>
  ); 
};

export default DownloadButton;
