import React, { useState, useEffect, useCallback, useMemo, useRef, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { useCompStore, usePersistedDataStore } from '../store.js';
import DownloadButton from './DownloadButton.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import SummaryCards from './SummaryCards';
import Typography from '@mui/material/Typography';

const SummaryCaseReviewTable = React.lazy(() => import('./SummaryCaseReviewTable'));

// This route calls get sales data every time you re-render it
// You should 

// Right now, negotiationCases is just the negotiation object.
//  do you want to constrain this to just the cases?
function ReviewSettlements({ handleUpdateStateCallback, updatedArray, negotiationCases, filterType, setFilterType, filteredNegotiationCases}) {
  
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
  const courtDate = queryParams.get('CourtDate') || 'All';
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const municipalityString = queryParams.get('municipalityString') || 'All';

  const totalIFMV = useMemo(() => {
    if(!negotiationCases) return 0;
    return negotiationCases.reduce((acc, item) => {
      if (item && item.IFMV) {
        return acc + item.IFMV;
      }
      return acc;
    }, 0);
  }, []);

  // Summary data for Haverstraw
  const summaryData = {
    totalPropertyValue: 3855738615,
    totalPropertyTax: 36558951,
    annualTrend: 0.04545,
    propertiesGrieved: Math.round(totalIFMV),
  }

  const fileName = `${MuniCode}_${taxYear}${courtDate === 'All' ? '' : `_${courtDate}`}`;
  const [selectionModel, setSelectionModel] = React.useState([]);

    // For the download
    const keys = ['parcel_id', 'Address', 'Town', 'ZipCode', 'ClientName', 'SCARIndexNumber','PropertyAssessment', 'IFMV', 'RunDate', 'SalePrice', 'SaleDate', 'RecentSale', 'Taxes', 'SubjectMarketValue', 'OfferValue', 'RAR', 'RequestedAssessment', 'PctReduction', 'settled', 'Comp1', 'Comp2', 'CaseNotes', 'RepID'];
    
    const handleCellClick = useCallback((selection) => {
      console.log("Row ID:", selection);
      setSelectionModel(selection);
    }, [selectionModel]);

    return (
    // On this page, if there's no settlements (or even if there are) - have an option to "save review" - this will save the comp order,
    // And save any min/max offers (and settlements)
<div className="h-full flex flex-col">
  
  {/* Summary Stats Section */}
  {/* <div className='mt-12'> */}
  {/* <SummaryCards summaryData={summaryData} /> */}
  <Typography variant="h5" align="center" gutterBottom>
        {municipalityString} Summary
  </Typography>

  <div className="flex flex-col flex-grow h-0">
    {/* Filtering and Download Div */}
    <div className="flex justify-between mb-1 mt-2 flex-shrink-0">
      <div className="flex">
        <FormControl>
          <InputLabel sx={{ zIndex: '1 !important', background: 'transparent' }}>
            Case Filter
          </InputLabel>
          <Select
            sx={{
              minWidth: 120,
              '& .MuiSelect-select': {
                py: 1,
              },
              '& .MuiInputBase-input': {
                py: 1,
              },
            }}
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            label="Filter Type"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="unreviewed">Unreviewed</MenuItem>
            <MenuItem value="open">Unsettled</MenuItem>
            <MenuItem value="scar">SCAR Filed</MenuItem>
            <MenuItem value="settled">Settled</MenuItem>
          </Select>
        </FormControl>
      </div>
      <DownloadButton
        caseData={negotiationCases}
        fileName={fileName}
        selectedRows={selectionModel}
        keys={keys}
      />
    </div>

    {/* Case Review Table */}
    
    <Suspense fallback={<CircularProgress />}>
          <SummaryCaseReviewTable
          updatedArray={updatedArray}
            handleCellClick={handleCellClick}
            selectionModel={selectionModel}
            filteredNegotiationCases={negotiationCases}
            negotiationCases={negotiationCases}
            handleUpdateStateCallback={handleUpdateStateCallback}
            // doneUpdating={getIsFetching}
          />
        </Suspense>
  </div>
  
</div>
  );
} export default ReviewSettlements
